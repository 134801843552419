import clientZoho from '../clientzoho';
import i18n from '../i18n';

const postApi = async (path, params) => {
  let lang = i18n.language;
  let apiResult;
  try {
    apiResult = await clientZoho.post(path, { ...params, lang: lang });
    const { data } = apiResult;
    return { success: true, data: data };
  } catch (err) {
    const { response } = err;
    const status = response && response.status ? response.status : err.message;
    const errormsg = response && response.data ? response.data.msg : err.message;
    const errorcode = response && response.data ? response.data.code : err.message;
    return { success: false, data: errorcode, errorstatus: status, errormsg: errormsg }
  }
}

const getApi = async (path, params) => {
  let lang = i18n.language;
  let trial = 0;
  
  const runApi = async () => {
    try {
      const { data } = await clientZoho.get(path, { ...params, lang: lang });
      return { success: true, data: data };
    } catch (err) {
      const { response } = err;
      const status = response && response.status ? response.status : err.message;
      const errormsg = response && response.data ? response.data.msg : err.message;
      return { success: false, data: null, errorstatus: status, errormsg: errormsg }
    }
  }
  
  let apiResult = await runApi();
  if( ! apiResult.success && trial < 3 ) {
    trial ++;
    apiResult = await runApi();
  } 
  return apiResult;
}

const sendSubscribeFormZoho = async (
  firstname, lastname, email, country, language, phone, comment, listid, action) => {
  const formDataToSend = {
    "email": email,
    "first_name": firstname,
    "last_name": lastname,
    "country": country,
    "language": language,
    "phone": phone,
    "comment": comment,
    "list_id": listid,
    "action":action
  };
  const apiResult = await postApi('zoho_api.php', formDataToSend);
  return apiResult;
}

export {
  sendSubscribeFormZoho
};