import day0 from '../../images/individual/day0.png';
import day1 from '../../images/individual/day1.png';
import day2 from '../../images/individual/day2.png';
import day3 from '../../images/individual/day3.png';
import day4 from '../../images/individual/day4.png';
import day5 from '../../images/individual/day5.png';
import day6 from '../../images/individual/day6.png';
import day7 from '../../images/individual/day7.png';

export const dayimages = {
  '0': day0, "1": day1, "2": day2, "3": day3,
  "4": day4, "5": day5, "6": day6, "7": day7
}