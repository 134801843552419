import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/utils/ScrollToTop';
import { useTranslation } from "react-i18next";
import Content from './Content';
import CookieAlert from './components/utils/CookieAlert';
import LandingInfo from './components/utils/LadningInfo';
import ReactGA from "react-ga4";

function App() {

  const { ready } = useTranslation();
  const [showCookieAlert, setShowCookieAlert] = useState(true);

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_M_ID);
  ReactGA.send('pageview');

  if (ready) {
    return (
      <>
        <Router basename={process.env.PUBLIC_URL}>
          <ScrollToTop></ScrollToTop>
          <Content setShowCookieAlert={setShowCookieAlert}></Content>
          <LandingInfo></LandingInfo>
          <CookieAlert showCookieAlert={showCookieAlert}></CookieAlert>
        </Router >
      </>
    );
  }
  return (<span>loading...</span>)
}

export default App;
