import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../../clientwrapper';
import { getText } from '../../utils/htmhelper';

const Members = (props) => {
  const { texts } = props;
  const [mounted, setMounted] = useState(true);
  const [members, setMembers] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(10);
      const { data } = apiResult;
      setMembers(data);
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);


  return (
    <div className="members-container">
      <div className="members-title">
        {texts && texts.acf.title}
      </div>
      <div className="members-members">
        {members && members.map((item, i) => (
          <div className="member-item" key={i}>
            <div className="member-image"><img src={item.acf.picture.url} alt=""></img></div>
            <div className="member-text"
              dangerouslySetInnerHTML={texts && getText(item.content.rendered)}>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Members;