import React, { useCallback, useEffect, useState, useRef } from 'react';
import { checkInput } from '../../utils/validators';
import { useTranslation } from 'react-i18next';
import CountrySelector from '../utils/CountrySelector';
import { getText, getHtmlText } from '../../utils/htmhelper';
import LanguageSelector from '../utils/LanguageSelector';
import PhoneNumberInput from '../utils/PhoneNumberInput';
import { Link, useHistory } from 'react-router-dom';
import mujerImg from '../../images/register/mujer.png';
import { useDispatch, useSelector } from 'react-redux';
import { sendRegistrationMessage, resetSubscribe } from '../../redux/actions/userActions';
import MessageBox from '../utils/MessageBox';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import EventSelector from '../utils/EventSelector';

const RegisterFormGroup = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { texts, event, events, nextEvent, eventData, type } = props;
  const isDefault = (type === 'default');
  const isDisplaySimple = (eventData && eventData.acf && eventData.acf.registerform_type === 'simple');
  
  const dispatch = useDispatch();
  const userSubscribe = useSelector((state) => state.userSubscribe);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');
  const [eventList, setEventList] = useState([]);
  const [msgBox, setMsgBox] = useState(t('contact.messages.register_success_group'));

  const { groupstatus, loading, msg } = userSubscribe;
  
  const Refs = {
    firstname: useRef(null),
    lastname: useRef(null),
    email: useRef(null),
    toc: useRef(null)
  };

  const initialState = {
    firstname: '', lastname: '', email: '', country: { value: '', label: '' }, language: { value: '', label: '' }, phone: '',
    toc: '', comment: '', emailfrom: '', event: { value: '', label: '' }
  };

  const initialStateError = {
    firstname: '', lastname: '', email: '', country: '', language: '', phone: '', toc: '',
    comment: '', emailfrom: '', event: ''
  };

  const [formData, setFormData] = useState(initialState);
  const [formDataError, setFormDataError] = useState(initialStateError);
  const [showMsg, setShowMsg] = useState(false);

  let fieldsValidators = [
    { name: 'firstname', value: formData.firstname, validators: ['empty'] },
    { name: 'lastname', value: formData.lastname, validators: ['empty'] },
    { name: 'email', value: formData.email, validators: ['empty', 'email'] },
    { name: 'country', value: formData.country.value, validators: [] },
    { name: 'language', value: formData.language.value, validators: [] },
    { name: 'phone', value: formData.phone, validators: [] },
    { name: 'toc', value: formData.toc, validators: ['checkbox'] },
    { name: 'comment', value: formData.comment, validators: [] },
    { name: 'emailfrom', value: formData.emailfrom, validators: [] },
    { name: 'event', value: formData.event, validators: [] }
  ];

  if( isDisplaySimple ) {
    fieldsValidators = [
      { name: 'email', value: formData.email, validators: ['empty', 'email'] },
      { name: 'toc', value: formData.toc, validators: ['checkbox'] }
    ];
  }

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked ? '1' : 0 : target.value;
    const name = target.name;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    const field = fieldsValidators.find(element => element.name === name);
    const referenceValue = '';
    const errorMsg = checkInput(
      { inputName: name, inputValue: value, referenceValue: referenceValue }, field.validators);
    setFormDataError(prevState => ({ ...prevState, [name]: errorMsg }));
  };

  const validateInput = () => {
    const hasError = fieldsValidators.some(field => {
      const referenceValue = null;
      const errorMsg = checkInput(
        { inputName: field.name, inputValue: field.value, referenceValue: referenceValue },
        field.validators);
      if (errorMsg) {
        setFormDataError(prevState => ({ ...prevState, [field.name]: errorMsg }));
        Refs[field.name].current.scrollIntoView(true);
        return true;
      }
      setFormDataError(prevState => ({ ...prevState, [field.name]: '' }));
      return false;
    });

    return hasError;
  };

  const setCountry = country => {
    setFormData(prevState => ({ ...prevState, country }));
  }

  const setLanguage = language => {
    setFormData(prevState => ({ ...prevState, language }));
  }

  const setPhone = phone => {
    setFormData(prevState => ({ ...prevState, phone }));
  }

  const setEvent = event => {
    setFormData(prevState => ({ ...prevState, event }));
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (token === null || token === '') {
      return;
    }
    if (formData.emailfrom !== '') {
      return;
    }
    // filter comment with url
    const urlRE = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
    if (formData.comment !== '' && formData.comment.match(urlRE)) {
      return;
    }
    submitForm();
  }

  const submitForm = () => {
    setFormDataError(initialStateError);
    const isInvalid = validateInput();
    if (!isInvalid) {
      dispatch(sendRegistrationMessage(
        formData.firstname, formData.lastname, formData.email, formData.country.label,
        formData.language.label, formData.phone, formData.comment, 'group', formData.event.value
      ));
    }
  };

  const resetMsg = () => {
    dispatch(resetSubscribe());
    setShowMsg('');
    if( groupstatus !== 'error' ) {
      history.push("/");
    }
    
    // reset form values
    //setFormData(initialState);
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0px'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '4.8rem'
    }),
    menuList: (provided, state) => ({
      ...provided,
      height: '10rem'
    }),
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha('yourAction');
    setToken(token);
  }, [executeRecaptcha]);


  useEffect(() => {    
    document.getElementsByClassName('main-content')[0].style.transform = 'none';
    
    if (token === '') {
      handleReCaptchaVerify();
    }
    if (groupstatus) {
      setShowMsg(groupstatus);
    }
    if (events && Array.isArray(events)) {
      let eList = [];
      events.forEach((e) => {
        let beginDate = e.acf.begin;
        let listId = e.acf.list_id && e.acf.list_id !== '' ? e.acf.list_id : process.env.REACT_APP_DEFAULT_LIST_ID;
        eList.push({ value: listId, label: beginDate });
      });
      setEventList(eList);
      // set event id if custom
    }
    if (event) {
      setEvent({ label: event.eventLabel, value: event.eventId });
    } else if (nextEvent) {
      setEvent({ label: nextEvent.acf.begin, value: nextEvent.acf.list_id });
    }
    
    if( ! isDefault && eventData && eventData.acf.list_id && eventList ) {
      eventList.forEach( event => {
        if( parseInt(event.value) === parseInt(eventData.acf.list_id) ) {
          setEvent(event);
        }
      });
    }

    if( eventData && eventData.acf ) {
      setEvent({ label: '', value: eventData.acf.list_id });
    }


    const userType = msg && msg.user_status ? msg.user_status : null;
  
    if( userType && userType === 'new' ) {
      if( eventData && eventData.acf.success_registration_message && eventData.acf.success_registration_message !== '' ) {
        setMsgBox(eventData.acf.success_registration_message);
      } 
    } else if( userType && userType === 'registered' ) {
      if( eventData && eventData.acf.success_registration_message_for_registered_users 
        && eventData.acf.success_registration_message_for_registered_users !== '' ) {
        setMsgBox(eventData.acf.success_registration_message_for_registered_users);
    } 
  }

  }, [props, groupstatus, handleReCaptchaVerify, token, events, event, nextEvent, isDefault, eventData, msg]);
  
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA3_SITE_KEY}>
      {showMsg ?
        <MessageBox
          variant={groupstatus === 'error' ? 'danger' : 'info'}
          resetMsg={resetMsg}>
            {
              groupstatus === 'error' ? t('contact.messages.' + msg) : 
              <div dangerouslySetInnerHTML={getHtmlText(msgBox)}></div> 
            }
        </MessageBox>
        : null
      }
      <div className="register-container register-group">
        <div className="register-left register-group">
          <div className="register-header">
            <div className="register-title">
              {isDefault ? (texts && texts.acf.title) 
                : (eventData && eventData.acf.registerform_title ? eventData.acf.registerform_title : (texts && texts.acf.title)) }
            </div>
            <div className="register-text"
              dangerouslySetInnerHTML={
                isDefault ? (texts && getText(texts.acf.text)) 
                  : (eventData && eventData.acf.registerform_text ? getText(eventData.acf.registerform_text) : (texts && getText(texts.acf.text)) 
                  ) }></div>
          </div>
          <div className="form-separator"></div>
          <div className="register-form-container">

            <form noValidate onSubmit={(e) => onSubmit(e)}>
              <div className="hidden">
                <input type="text" name="emailfrom" value={formData.submitForm}
                  onChange={(e) => handleInputChange(e)}>
                </input>
                <input type="text" name="event" value={formData.event}
                  onChange={(e) => handleInputChange(e)}>
                </input>
              </div>

              <div className={ isDisplaySimple ? 'hidden' : 'register-row'}>
                <div className="input-container input-firstname">
                  <div ref={Refs.firstname} className="scrollto"></div>
                  <div className="input-label">
                    {t('contact.firstname')}
                  </div>
                  <div className="input-input">
                    <input formNoValidate
                      className={`input ${formDataError.firstname ? "inputerror" : ""}`}
                      type="text" name="firstname" onChange={(e) => handleInputChange(e)}
                      value={formData.firstname}
                    />
                    {formDataError.firstname && <div className='error'>
                      {t('contact.errors.' + formDataError.firstname)}
                    </div>}
                  </div>
                </div>

                <div className="input-container input-lastname">
                  <div ref={Refs.lastname} className="scrollto"></div>
                  <div className="input-label">
                    {t('contact.lastname')}
                  </div>
                  <div className="input-input">
                    <input formNoValidate
                      className={`input ${formDataError.lastname ? "inputerror" : ""}`}
                      type="text" name="lastname" onChange={(e) => handleInputChange(e)}
                      value={formData.lastname}
                    />
                    {formDataError.lastname && <div className='error'>
                      {t('contact.errors.' + formDataError.lastname)}
                    </div>}
                  </div>
                </div>
              </div>

              <div className="register-row">
                <div className="input-container input-email">
                  <div ref={Refs.email} className="scrollto"></div>
                  <div className="input-label">
                    {t('contact.email')}
                  </div>
                  <div className="input-input">
                    <input formNoValidate
                      className={`input ${formDataError.email ? "inputerror" : ""}`}
                      type="email" name="email" onChange={(e) => handleInputChange(e)}
                      value={formData.email}
                    />
                    {formDataError.email && <div className='error'>
                      {t('contact.errors.' + formDataError.email)}
                    </div>}
                  </div>
                </div>

                <div className={ isDisplaySimple ? 'hidden' : 'input-container input-country'}>
                  <div className="input-label">
                    {t('contact.country')}
                  </div>
                  <div className="input-input">
                    <CountrySelector setValue={setCountry}
                      customStyles={customStyles} value={formData.country}></CountrySelector>
                    {formDataError.country && <div className='error'>
                      {t('contact.errors.' + formDataError.country)}
                    </div>}
                  </div>
                </div>
              </div>

              <div className={isDisplaySimple ? 'hidden' : 'register-row'}>
                <div className="input-container input-language">
                  <div className="input-label">
                    {t('contact.language')}
                  </div>
                  <div className="input-input">
                    <LanguageSelector
                      setValue={setLanguage}
                      customStyles={customStyles} value={formData.language}></LanguageSelector>
                    {formDataError.language && <div className='error'>
                      {t('contact.errors.' + formDataError.language)}
                    </div>}
                  </div>
                </div>

                <div className="input-container input-phone">
                  <div className="input-label">
                    {t('contact.phone')}
                  </div>
                  <div className="input-input">
                    { /* <input formNoValidate
                      className={`input ${formDataError.phone ? "inputerror" : ""}`}
                      type="text" name="phone" onChange={(e) => handleInputChange(e)}
                      value={formData.phone}
              /> */}
                    
                     <PhoneNumberInput
                      setValue={setPhone}
                    >
                    </PhoneNumberInput>

                    {formDataError.phone && <div className='error'>
                      {t('contact.errors.' + formDataError.phone)}
                    </div>}
                  </div>
                </div>
              </div>

              <div className={isDisplaySimple ? 'hidden' : 'form-separator'}></div>

              <div className={isDisplaySimple ? 'hidden' : 'register-row'}>

                <div className="input-container input-comment">
                  <div className="input-label">
                    {t('contact.comment')}
                  </div>
                  <div className="input-input">
                    <input formNoValidate
                      className={`input ${formDataError.comment ? "inputerror" : ""}`}
                      type="text" name="comment" onChange={(e) => handleInputChange(e)}
                      value={formData.comment}
                    />
                    {formDataError.comment && <div className='error'>
                      {t('contact.errors.' + formDataError.comment)}
                    </div>}
                  </div>
                </div>

                <div className={ isDefault ? 'input-container input-event' : 'hidden'}>

                  <div className="input-label">
                    {t('contact.event')}
                  </div>
                  <div className="input-input">
                    <EventSelector
                      setValue={setEvent}
                      customStyles={customStyles} value={formData.event}
                      eventList={eventList}></EventSelector>
                    {formDataError.event && <div className='error'>
                      {t('contact.errors.' + formDataError.event)}
                    </div>}
                  </div>
                </div>
              </div>

              <div className="form-separator"></div>

              <div className="register-row">
                <div>
                  <div className="input-container toc-container">
                    <div ref={Refs.toc} className="scrollto"></div>

                    <input type="checkbox" className="toc"
                      onChange={(e) => handleInputChange(e)}
                      name="toc" checked={formData.toc}></input>
                    <label className="label label-toc">
                      {t('contact.agreetoc')}
                      <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
                        <span className="toc-link">
                          {t('contact.toc_link')}
                        </span>
                      </Link>
                    </label>
                  </div>
                  {formDataError.toc && <div className='error'>
                    {t('contact.errors.' + formDataError.toc)}
                  </div>}
                </div>
                <div className="button-container input-container">
                  <div className="button-inner">
                    <button className="button" disabled={loading}>
                      {loading ? t('contact.loading') 
                        : isDefault ? t('contact.register') : (eventData && eventData.acf.registerform_submit_text ? eventData.acf.registerform_submit_text :
                          t('contact.register') )
                      }
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
        <div className="register-right"
          style={{ backgroundImage: `url(${mujerImg})` }}
        ></div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default RegisterFormGroup;