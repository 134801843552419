import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import Day from '../components/days/Day';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

const Days = (props) => {

  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const animParams = getAnimationParams('page');

  const dayNumber = props.match.params.day;

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_DAYS);
      const { data, success } = apiResult;
      if (success) {
        let filteredText = [];
        data.forEach((datatext) => {
          filteredText[datatext.title.rendered] = datatext;
        })
        setTexts(filteredText);
      }
    };
    if (mounted && !texts) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted, texts]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      {texts ? (<Day texts={texts && texts['Day' + dayNumber]} daynumber={dayNumber}></Day>) : (<span>Loading...</span>)}

    </motion.div>
  );
};

export default Days;