import React from 'react';
import { getText } from '../../utils/htmhelper';
import intImage from '../../images/group/international_program.png';

const InternationalProgram = (props) => {
  const { texts } = props;

  return (
    <div className="group-international-container">
      <div className="group-international-image">
        <img src={intImage} alt=""></img>
      </div>
      <div className="group-international-text-container">
        <div className="group-international-title">
          {texts && texts.acf.title}
        </div>
        <div className="group-international-text"
          dangerouslySetInnerHTML={texts && getText(texts.acf.text)} >
        </div>
      </div>
    </div>
  );
};

export default InternationalProgram;