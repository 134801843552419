import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../../clientwrapper';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonyBg from '../../images/testimony/testimony_bg.png';

function Testimony(props) {

  const { texts } = props;
  const [testimonies, setTestimonies] = useState(null);
  const [mounted, setMounted] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  function getText(text) {
    return { __html: text };
  }

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_TESTIMONIES);
      const { data, success } = apiResult;
      if (success) {
        setTestimonies(data);
      }
    };
    if (mounted) { fetchData(); }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <div id="testimonies" ref={props.refProp} className="testimony-container">
      <div className="testimony-title">
        <span className="title1">{texts && texts.acf.title}</span> <span className="title2">{texts && texts.acf.title_extra}</span>
      </div>
      <Slider {...settings}>
        {testimonies && testimonies.map((testimony) => (
          <div key={testimony.id}>
            <div className="testimony flexrow">
              <div className="testimony-text"
                style={{ backgroundImage: `url(${testimonyBg})` }}>
                <div className="testimony" dangerouslySetInnerHTML={getText(testimony.content.rendered)}>
                </div>
              </div>
              <div className="testimony-image">
                <img src={testimony.acf.picture.url} alt={testimony.title.rendered}></img>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {/* 
      <div className="testimony-share">
        <div className="testimony-share-inner">
          <div className="testimony-share-img"><img src={shareImage} alt=""></img></div>
          <Link to="/contact">
            <div className="testimony-share-text"
              dangerouslySetInnerHTML={texts && getText(texts.acf.footer)}>
            </div>
          </Link>
        </div>
      </div>
*/}
    </div>
  );
}

export default Testimony;