import React, { useState, useMemo, useEffect } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useTranslation } from 'react-i18next';

function CountrySelector(props) {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const countrylist = countryList();
  const countries = useMemo(() => countrylist.getData(), [countrylist]);
  //const { customStyles } = props;

  const changeHandler = value => {
    setValue(value);
    props.setValue(value);
  }

  const customStylesAll = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0px'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '4.8rem',
      fontSize: '1.4rem'
    }),
    menuList: (provided, state) => ({
      ...provided,
      height: '10rem',
      fontSize: '1.4rem'
    }),
  };

  useEffect(
    () => {
      if (props.value) {
        //const countryLabel = countrylist.getLabel(props.value.toLowerCase());
        //const countryValue = { "value": props.value, "label": countryLabel };
        const countryValue = props.value;
        setValue(countryValue);
      }
    }, [props]
  );

  return <Select options={countries} className="country-selector-container"
    value={value} onChange={changeHandler} placeholder={t('contact.selectcountry')}
    styles={customStylesAll} />
}

export default CountrySelector