import React, { useEffect, useState } from 'react';
import { getPostsFromCategory, unsubscribeSubscriber } from '../clientwrapper';
import { getHtmlText } from '../utils/htmhelper';
import lightCircle1 from '../images/subscription/light_circle_1.png';
import logo1 from '../images/subscription/logo1.png';


const UnsubscribePage = (props) => {

  const [mounted, setMounted] = useState(true);
  const [isSuccess, setIsSuccess] = useState(null);
  const [texts, setTexts] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_SUBSCRIPTION);
      const { data } = apiResult;
      const filteredText = data[0];
      setTexts(filteredText);
    };

    const modUnsubscribeSubscriber = async () => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const userToken = params.get('nt');
      const userEmail = params.get('ne');
      const listId = params.get('nl');

      if (userToken !== '' && userEmail !== '' && listId !== '') {
        const apiResult = await unsubscribeSubscriber(userToken, userEmail, listId);
        const { success } = apiResult;
        if (success) {
          setIsSuccess(true);
        } else {
          setIsSuccess(false);
        }
      } else {
        setIsSuccess(false);
      }
    };
    if (mounted) {
      modUnsubscribeSubscriber();
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);


  return (
    <div className="activate-registration-container unscubscribe">
      <div className="circle-container">
        <img src={lightCircle1} alt=''></img>
      </div>
      {isSuccess != null && isSuccess &&
        (
          <>
            <div className="subscription-title" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.confirm_unsubscribe_success_title)}></div>
            <div className="subscription-text" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.confirm_unsubscribe_success_text)}></div>
          </>
        )
      }
      {isSuccess != null && !isSuccess &&
        (<>
          <div className="subscription-title" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.confirm_registration_error_title)}></div>
          <div className="subscription-text" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.confirm_registration_error_text)}></div>
        </>)
      }
      <div className="logo-container">
        <img src={logo1} alt="logo"></img>
      </div>
    </div>
  );
};

export default UnsubscribePage;