import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import Contact from '../components/contact/Contact';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

const ContactPage = (props) => {

  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState([]);
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_CONTACT);
      const { data } = apiResult;
      let filteredText = [];
      data.forEach((datatext) => {
        filteredText[datatext.title.rendered] = datatext;
      })
      setTexts(filteredText);
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <Contact texts={texts['Contact']}></Contact>

    </motion.div>
  );
};

export default ContactPage;