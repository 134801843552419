import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import backImg from '../../images/days/go_back.png';
import additionalyFamily from '../../images/whatsnext/additionaly_family.png';
import additionalyTeam from '../../images/whatsnext/additionaly_team.png';
import additionalyPark from '../../images/whatsnext/additionaly_park.png';
import additionalyRedo from '../../images/whatsnext/additionaly_redo.png';
import additionalySocial from '../../images/whatsnext/additionaly_social.png';
import additionalySeparator from '../../images/whatsnext/additionaly_separator.png';
import quoteBg from '../../images/whatsnext/quote_bg.png';
import { getText } from '../../utils/htmhelper';
import { useTranslation } from 'react-i18next';


const YouCan = (props) => {

  const { texts } = props;
  const { t } = useTranslation();

  const [selectedContinent, setSelectedContinent] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const [parks, setParks] = useState([]);

  const selectContinent = (continent) => {
    setSelectedCountry('');
    const prevContinent = selectedContinent;
    if (continent === prevContinent) {
      setSelectedContinent('');
    } else {
      setSelectedContinent(continent);
    }
  };

  const selectCountry = (country) => {
    const prevCountry = selectedCountry;
    if (country === prevCountry) {
      setSelectedCountry('');
    } else {
      setSelectedCountry(country);
    }
  };

  const getParks = () => {
    fetch('parks.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
    ).then(function (response) {
      return response.json();
    }).then(function (parksJson) {
      setParks(parksJson)
    });
  };

  useEffect(() => {
    getParks()
  }, []);

  return (
    <>
      <div className="youcan-container">
        <div className="title">
          {texts && texts.acf.title}
        </div>
        <div className="list-container">
          <div className="item-1 item">
            <div className="item-logo">
              <img src={additionalyFamily} alt="family"></img>
            </div>
            <div className="item-text-container">
              <div className="item-title">{texts && texts.acf.family_title}</div>
              <div className="item-text" dangerouslySetInnerHTML={texts && getText(texts.acf.family_text)}></div>
            </div>
          </div>
          <div className="item-separator">
            <div className="spacer"></div>
            <img src={additionalySeparator} alt=""></img>
          </div>
          <div className="item-2 item">
            <div className="item-logo">
              <img src={additionalyTeam} alt="team"></img>
            </div>
            <div className="item-text-container">
              <div className="item-title">{texts && texts.acf.team_title}</div>
              <div className="item-text" dangerouslySetInnerHTML={texts && getText(texts.acf.team_text)}></div>
            </div>
          </div>
          <div className="item-separator">
            <div className="spacer"></div>
            <img src={additionalySeparator} alt=""></img>
          </div>
          <div className="item-3 item">
            <div className="item-logo">
              <img src={additionalyPark} alt="team"></img>
            </div>
            <div className="item-text-container">
              <div className="item-title">{texts && texts.acf.park_title}</div>
              {
                parks.map((continent, c) => {
                  return (

                    <div key={c} className="item-text">
                      <u className="link" onClick={() => { selectContinent(continent.continent) }}>{continent.continent}</u>
                      {
                        continent.countries.map((country, cc) => {
                          return (
                            <div key={cc} className={selectedContinent === continent.continent ? '' : 'hidden'}
                              onClick={() => { selectCountry(country.country) }}
                            >
                              <i className="link">{country.country}</i>
                              {
                                country.parks.map((park, p) => {
                                  return (
                                    <ul key={p}
                                      className={selectedCountry === country.country ? 'link' : "link hidden"}>
                                      <li><a href={park.web} target="_blank" rel="noreferrer">{park.name}</a></li>
                                    </ul>
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }


            </div>
          </div>
          <div className="break"></div>
          <div className="item-4 item">
            <div className="item-logo">
              <img src={additionalyRedo} alt="team"></img>
            </div>
            <div className="item-text-container">
              <div className="item-title">{texts && texts.acf.redo_title}</div>
              <div className="item-text" dangerouslySetInnerHTML={texts && getText(texts.acf.redo_text)}></div>
            </div>
          </div>
          <div className="item-separator item-separator-bottom">
            <div className="spacer"></div>
            <img src={additionalySeparator} alt=""></img>
          </div>
          <div className="item-5 item">
            <div className="item-logo">
              <img src={additionalySocial} alt="team"></img>
            </div>
            <div className="item-text-container">
              <div className="item-title">{texts && texts.acf.social_title}</div>
              <div className="item-text" dangerouslySetInnerHTML={texts && getText(texts.acf.social_text)}></div>
            </div>
          </div>

        </div>
      </div>
      <div className="wn-footer-container">
        <div className="quote-container" style={{ backgroundImage: `url(${quoteBg})` }}>
          <div className="quote" dangerouslySetInnerHTML={texts && getText(texts.acf.quote)}></div>
        </div>
        <div className="footer-images">
          <img src={texts && texts.acf.image1} alt=""></img>
          <img src={texts && texts.acf.image2} alt=""></img>
        </div>
        <div className="navigation-container">
          <div className="navigation-inner">
            <div className="navigation-back">
              <Link to="/conclusions">
                <div><img src={backImg} alt="<-"></img></div>
              </Link>
              <div className="back-text">{t('whatsnext.backconclusion')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YouCan;