import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const PhoneNumberInput = (props) => {
  const [value, setValue] = useState('');

  const changeHandler = value => {
    setValue(value);
    props.setValue(value);
  };

  useEffect(
    () => {
      if (props.value) {
        setValue(value);
      }
    }, [props, value]
  );

  return (
    <PhoneInput
      placeholder="Enter phone number"
      value={value}
      onChange={changeHandler} />
  );
};

export default PhoneNumberInput;