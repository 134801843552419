import { sendContactForm, sendSubscribeForm } from '../../clientwrapper';
import { sendSubscribeFormZoho } from '../../clientwrapperzoho';

import {
  USER_CONTACT_FAIL, USER_CONTACT_SUCCESS, USER_CONTACT_REQUEST, USER_CONTACT_RESET,
  USER_SUBSCRIBE_FAIL, USER_SUBSCRIBE_SUCCESS, USER_SUBSCRIBE_GROUP_SUCCESS, USER_SUBSCRIBE_REQUEST, USER_SUBSCRIBE_RESET
} from '../constants/userConstants';

export const sendContactMessage = (name, country, email, message, checker) => async (dispatch, getState) => {
  dispatch({ type: USER_CONTACT_REQUEST, payload: {} });
  const result = await sendContactForm(name, country, email, message, checker);
  const { success, data } = result;
  if (success) {
    dispatch({ type: USER_CONTACT_SUCCESS, payload: data });
  }
  else { dispatch({ type: USER_CONTACT_FAIL, payload: data }); }
}

export const resetContact = () => async (dispatch, getState) => {
  dispatch({ type: USER_CONTACT_RESET, payload: {} });
}

export const sendRegistrationMessage = (
  firstname, lastname, email, country, language, phone, comment, subscriptionType, listid) => async (dispatch, getState) => {
    dispatch({ type: USER_SUBSCRIBE_REQUEST, payload: {} });
    const result = await sendSubscribeFormZoho(
      firstname, lastname, email, country, language, phone, comment, listid, subscriptionType);
    const { success, data } = result;
    if (success) {
      if( subscriptionType === 'group') {
        dispatch({ type: USER_SUBSCRIBE_GROUP_SUCCESS, payload: data });
      } else {
        dispatch({ type: USER_SUBSCRIBE_SUCCESS, payload: data });
      }
    }
    else { dispatch({ type: USER_SUBSCRIBE_FAIL, payload: data }); }
  }

export const resetSubscribe = () => async (dispatch, getState) => {
  dispatch({ type: USER_SUBSCRIBE_RESET, payload: {} });
}
