import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import "./i18n";
import './scss/main.scss';
import store from './redux/store';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA3_SITE_KEY}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined
        }}
      >
        <App />
      </GoogleReCaptchaProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
