import React from 'react';
import { getText } from '../../utils/htmhelper';

const Origins = (props) => {
  const { texts } = props;
  return (
    <div className="origins-container">
      <div className="origins-title">
        {texts && texts.acf.title}
      </div>
      <div className="origins-text1"
        dangerouslySetInnerHTML={texts && getText(texts.acf.text1)}>
      </div>
      <div className="map-container">
        <div className="map-title">
          {texts && texts.acf.maptitle}
        </div>
        <div className="map-inner">
          <div className="map-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.text2)}>
          </div>
          <div className="map">
            <iframe title="map" src="https://www.google.com/maps/d/embed?mid=1ParoxB8wcAkf6EGzyJCfkj37B8CuLplD&ehbc=2E312F" width="100%" height="100%"></iframe>
          </div>

        </div>
      </div>
      <div className="origins-text3"
        dangerouslySetInnerHTML={texts && getText(texts.acf.text3)}>
      </div>
    </div>
  );
};

export default Origins;