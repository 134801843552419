import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import { getText } from '../utils/htmhelper';

const PrivacyPolicy = (props) => {
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_PRIVPOL);
      const { data } = apiResult;
      const filteredText = data[0];
      setTexts(filteredText);
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <div className="pp-container">
      <div className="pp-title" dangerouslySetInnerHTML={texts && getText(texts.title.rendered)}></div>
      <div className="pp-content" dangerouslySetInnerHTML={texts && getText(texts.content.rendered)} ></div>
    </div>
  );
};

export default PrivacyPolicy;