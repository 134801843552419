import {
  USER_CONTACT_FAIL, USER_CONTACT_SUCCESS, USER_CONTACT_REQUEST, USER_CONTACT_RESET,
  USER_SUBSCRIBE_FAIL, USER_SUBSCRIBE_SUCCESS, USER_SUBSCRIBE_REQUEST, USER_SUBSCRIBE_RESET,
  USER_SUBSCRIBE_GROUP_SUCCESS
}
  from '../constants/userConstants';


export const contactReducer = (state = { loading: false, msg: null, status: null }, action) => {
  switch (action.type) {
    case USER_CONTACT_REQUEST:
      return { ...state, loading: true }
    case USER_CONTACT_SUCCESS:
      return { ...state, loading: false, msg: action.payload, status: 'success' }
    case USER_CONTACT_FAIL:
      return { ...state, loading: false, msg: action.payload, status: "error" }
    case USER_CONTACT_RESET:
      return { ...state, error: null, msg: null, status: null }
    default:
      return state;
  }
}

export const subscribeReducer = (state = { loading: false, msg: null, status: null, groupstatus: null }, action) => {
  switch (action.type) {
    case USER_SUBSCRIBE_REQUEST:
      return { ...state, loading: true }
    case USER_SUBSCRIBE_SUCCESS:
      return { ...state, loading: false, msg: action.payload, status: 'success' }
    case USER_SUBSCRIBE_GROUP_SUCCESS:
        return { ...state, loading: false, msg: action.payload, groupstatus: 'success' }
    case USER_SUBSCRIBE_FAIL:
      return { ...state, loading: false, msg: action.payload, status: "error" }
    case USER_SUBSCRIBE_RESET:
      return { ...state, error: null, msg: null, status: null, groupstatus:null }
    default:
      return state;
  }
}