export const getAnimationParams = (pageUrl) => {
  
  const windowHeight = window.innerHeight;

  const animationParams = {
    'page': {
      transition: { delay: 0.2, duration: 1 },
      initial: { opacity: 0, transform: 'translate3d(100vw, 0, 0)' },
      animate: { opacity: 1, transform: 'translate3d(0vw, 0, 0)' },
      animExit: { opacity: 0, transform: 'translate3d(-100vw, 0, 0)' }
    },
    'program': {
      transition: { delay: 0.2, duration: 1 },
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      animExit: { opacity: 0 }
    },
    'signup': {
      transition: { delay: 0.2, duration: 1 },
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      animExit: { opacity: 0 }
    },
    'landing': {
      transition: { delay: 0.2, duration: 1 },
      initial: { opacity: 0, top:-windowHeight },
      animate: { opacity: 1, top:"50%" },
      animExit: { opacity: 0 }
    },
  }

  return animationParams[pageUrl] ? animationParams[pageUrl] : animationParams['page'];
}