import React, { useState, useRef, useEffect } from 'react'
import { BsArrowLeftShort } from "react-icons/bs"
import { BsArrowRightShort } from "react-icons/bs"
import playButton from '../../images/asking-audio/play_button.png';
import pauseButton from '../../images/homepage/buttonAudioPause.png';
import headPhoneImage from '../../images/asking-audio/headphones.png';
import { useTranslation } from 'react-i18next';

const AudioPlayer = (props) => {
  const { t } = useTranslation();
  // state
  const { isMultiple, audiofiles, playertitle, audio_player_title_color } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioSource, setAudioSource] = useState(audiofiles[0]);
  const [durations, setDurations] = useState({});
  const [preloaded, setPreloaded] = useState(false);
  const actPlayButton = props.playbutton ? props.playbutton : playButton;
  const actPauseButton = props.pausebutton ? props.pausebutton : pauseButton;

  const audioPlayer = useRef();   // reference our audio component
  const progressBar = useRef();   // reference our progress bar
  const animationRef = useRef();  // reference the animation

  useEffect(() => {
    const setAudioDurations = (i, d) => {
      let prevDurations = durations;
      prevDurations[i] = d;
      setDurations(prevDurations);
    }

    const preloadAudios = () => {
      if (preloaded) {
        return;
      }
      audiofiles.forEach((audiofile, i) => {
        var audio = new Audio();
        audio.addEventListener('loadedmetadata', () => {
          setAudioDurations(i, audio.duration);
          setAudioSource(audiofile);
          progressBar.current.max = audio.duration;
        });
        audio.src = audiofile;
      });
      setPreloaded(true);
    }

    /*const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(seconds);
    progressBar.current.max = seconds; */

    preloadAudios();
  }, [preloaded, audiofiles, durations]);

  const selectSource = (source, n) => {
    setAudioSource(source);
    setIsPlaying(false);
    progressBar.current.max = durations[n];
  }

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
    setCurrentTime(progressBar.current.value);
  }

  const backThirty = () => {
    progressBar.current.value = Number(progressBar.current.value - 30);
    changeRange();
  }

  const forwardThirty = () => {
    progressBar.current.value = Number(progressBar.current.value + 30);
    changeRange();
  }

  return (
    <div className="ap-audio-player">
      <audio ref={audioPlayer} src={audioSource}
        preload="metadata" onLoadedMetadata={event => setDuration(event.target.duration)}
      ></audio>
      {isMultiple && (
        <div className="audio-player-left">
          <div className="select-audio">{t('audioplayer.choose')}</div>
          <div className="ap-player-source-selector">
            {audiofiles.map((audiofile, i) => (
              <div key={i} className="source" onClick={() => selectSource(audiofile, i)}>
                <div className="headphone"><img src={headPhoneImage} alt=''></img></div>
                <div className="source-duration">
                  {
                    durations[i] && (
                      <span>{!isNaN(durations[i]) && calculateTime(durations[i])}</span>
                    )
                  }
                  {
                    !durations[i] && duration && (
                      <span>{!isNaN(duration) && calculateTime(duration)}</span>
                    )
                  } min</div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="audio-player-right">
        {playertitle &&
          (<div className="player-title" style={{ color: `${audio_player_title_color ? audio_player_title_color : ''}` }} >
            {playertitle}
          </div>)}
        <div className="audio-player-control">
          <button onClick={togglePlayPause} className='play-pause'>
            {isPlaying ? <img src={actPauseButton} alt="pause" /> : <img src={actPlayButton} alt="play" />}
          </button>
        </div>
        <div className="audio-player-current">
          {/* current time */}
          <div className="current-time">{calculateTime(currentTime)}</div>
          {/* progress bar */}
          <div className="progress-bar-container">
            <input type="range" className="progress-bar-tmp" defaultValue="0" ref={progressBar} onChange={changeRange} />
          </div>
          {/* duration */}
          {duration && (
            <div className="duration">{duration && !isNaN(duration) && calculateTime(duration)}</div>
          )}
        </div>
      </div>
      <div className="tmp-hide">
        <button className="forward-backward" onClick={backThirty}><BsArrowLeftShort /> 30</button>
        <button className="forward-backward" onClick={forwardThirty}>30 <BsArrowRightShort /></button>
      </div>

    </div>
  )
};

export default AudioPlayer;