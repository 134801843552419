import React from 'react';
import { getText, formatText } from '../../utils/htmhelper';
import backgroundImage from '../../images/benefits/background.png';
import listSeparator from '../../images/benefits/list_separator.png';
import separator from '../../images/benefits/separator.png';

function Benefits(props) {

  const { texts } = props;
  const listItems = texts && texts.acf.list ? formatText(texts.acf.list).split('<br>') : [];

  return (
    <div id="benefits" ref={props.refProp} className="benefits-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="benefits-inner">
        <div className="benefits-text">
          <span dangerouslySetInnerHTML={texts && getText(texts.acf.text_1)} />
        </div>
        <div className="benefits-items-separator">
          <img src={separator} alt="" />
        </div>
        <div className="benefits-list"
          style={{ backgroundImage: `url(${separator})` }}>
          {listItems.map((item, i) => (
            <div className="benefits-list-item" key={i}>
              <div className="list-separator"><img src={listSeparator} alt=''></img></div>
              <div dangerouslySetInnerHTML={getText(item)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Benefits;