import React, { useEffect, useState } from 'react';
import { getHtmlText, getText } from '../../utils/htmhelper';
import arrowOpen from '../../images/faq/arrow_right.png';
import arrowClose from '../../images/faq/arrow_down.png';
import separator from '../../images/faq/separator.png';
import { motion, AnimatePresence } from 'framer-motion';
import allOpen from '../../images/faq/open_all.png';
import allClose from '../../images/faq/close_all.png';
import openAllSeparator from '../../images/faq/open_all_separator.png';
import { useTranslation } from 'react-i18next';

const FAQCategory = (props) => {

  const { t } = useTranslation();
  const { name, posts } = props;
  const [openItem, setOpenItem] = useState([]);
  const [openAll, setOpenAll] = useState(false);

  const variants = {
    visible: { opacity: 1, height: 'auto', transition: { duration: 0.5 } },
    hidden: {
      opacity: 0, height: 0, transition: { duration: 0.5 }
    }
  };

  const toggleItem = (itemId) => {
    let prevState = openItem;
    if (prevState.includes(itemId)) {
      prevState = openItem.filter((value, index, array) => {
        return value !== itemId;
      });
    } else {
      prevState = [...prevState, itemId]
    }
    setOpenItem(prevState);
  };

  const toggleAll = () => {
    if (!openAll) {
      let prevState = [];
      posts.forEach(function (p, i) {
        prevState = [...prevState, i]
      });
      setOpenItem(prevState);
    } else {
      setOpenItem([]);
    }
    setOpenAll(!openAll);
  };

  useEffect(() => {

  }, [openItem]);

  return (
    <div className="faq-category-container">
      <div className="faq-category-title"
        dangerouslySetInnerHTML={getText(name)}>
      </div>
      <div className="faq-answer-container">
        <div className="open-all-container link">
          <div onClick={toggleAll} className="open-all-inner">
            <img src={openAll ? allClose : allOpen} alt="">
            </img>
            <div className="openAll">
              {openAll ? t('faq.closeall') : t('faq.openall')}
            </div>
          </div>
          <div><img src={openAllSeparator} alt="" className="open-all-separator"></img></div>
        </div>
        {posts.map((p, i) => (
          <div key={i}>
            <div className="faq-answer">
              <div className="faq-answer-title" onClick={() => { toggleItem(i) }}>
                <img src={openItem.includes(i) ? arrowOpen : arrowClose} alt=""
                ></img>
                <span dangerouslySetInnerHTML={getText(p.title.rendered)}></span>
              </div>
              <AnimatePresence>
                <motion.div
                  variants={variants}
                  animate={openItem.includes(i) ? 'visible' : 'hidden'}
                  className="faq-answer-text"
                  dangerouslySetInnerHTML={getHtmlText(p.content.rendered)}>
                </motion.div>
              </AnimatePresence>
            </div>
            <div className="separator">
              <img src={separator} alt=""></img>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQCategory;