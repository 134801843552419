import React, { useState, useRef, useEffect } from 'react'
/*import { BsArrowLeftShort } from "react-icons/bs"
import { BsArrowRightShort } from "react-icons/bs"
import playButton from '../../images/asking-audio/play_button.png';
import pauseButton from '../../images/homepage/buttonAudioPause.png';
import headPhoneImage from '../../images/asking-audio/headphones.png';*/
import { useTranslation } from 'react-i18next';
import headPhoneIcon from '../../images/audioplayer-day/headphone.png';
import waveIcon from '../../images/audioplayer-day/wave.png';
import playIcon from '../../images/audioplayer-day/play.png';
import pauseIcon from '../../images/audioplayer-day/pause.png';
import backIcon from '../../images/audioplayer-day/back.png';
import forwardIcon from '../../images/audioplayer-day/forward.png';

const AudioPlayerDay = (props) => {
  const { t } = useTranslation();
  // state
  const { dayAudio, shortAsking, longAsking, day } = props;

  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioSource, setAudioSource] = useState(dayAudio);

  const [durations, setDurations] = useState({});
  const [preloaded, setPreloaded] = useState(false);

  const audioPlayer = useRef();   // reference our audio component
  const progressBar = useRef();   // reference our progress bar
  const animationRef = useRef();  // reference the animation

  useEffect(() => {
    const setAudioDurations = (i, d) => {
      let prevDurations = durations;
      prevDurations[i] = d;
      setDurations(prevDurations);
    }

    const preloadAudios = () => {
      if (preloaded) {
        return;
      }

      let audiofiles = [];
      if (dayAudio) {
        audiofiles = [dayAudio, shortAsking, longAsking];
      } else {
        audiofiles = [shortAsking, longAsking];
      }

      audiofiles.forEach((audiofile, i) => {
        var audio = new Audio();
        audio.addEventListener('loadedmetadata', () => {
          setAudioDurations(i, audio.duration);
          progressBar.current.max = audio.duration;
        });
        audio.src = audiofile;
      });

      setPreloaded(true);
      if (dayAudio) {
        setAudioSource(dayAudio);
      } else {
        setAudioSource(shortAsking);
      }
    }

    preloadAudios();
  }, [preloaded, durations, dayAudio, longAsking, shortAsking]);

  const selectSource = (source, n) => {
    setAudioSource(source);
    setIsPlaying(false);
    progressBar.current.max = durations[n];
  }

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
    setCurrentTime(progressBar.current.value);
  }

  /*const backThirty = () => {
    progressBar.current.value = Number(progressBar.current.value - 30);
    changeRange();
  }

  const forwardThirty = () => {
    progressBar.current.value = Number(progressBar.current.value + 30);
    changeRange();
  }*/

  const nextAudio = () => {
    if (dayAudio) {
      if (audioSource === dayAudio) {
        selectSource(shortAsking, 1);
      } else if (audioSource === shortAsking) {
        selectSource(longAsking, 2);
      } else if (audioSource === longAsking) {
        selectSource(dayAudio, 0);
      }
    } else {
      if (audioSource === shortAsking) {
        selectSource(longAsking, 1);
      } else if (audioSource === longAsking) {
        selectSource(shortAsking, 0);
      }
    }

  }

  const pevAudio = () => {
    if (dayAudio) {
      if (audioSource === dayAudio) {
        selectSource(longAsking, 2);
      } else if (audioSource === shortAsking) {
        selectSource(dayAudio, 0);
      } else if (audioSource === longAsking) {
        selectSource(shortAsking, 1);
      }
    } else {
      if (audioSource === shortAsking) {
        selectSource(longAsking, 1);
      } else if (audioSource === longAsking) {
        selectSource(shortAsking, 0);
      }
    }
  }

  return (
    <>
      <div className="audio-player-day-container">
        <audio ref={audioPlayer} src={audioSource}
          preload="metadata" onLoadedMetadata={event => setDuration(event.target.duration)}
        ></audio>
        <div className="audio-player-day-left">
          <div className="audio-player-day-left-inner">
            <div className="audio-list">

              {dayAudio && (
                <div className={audioSource === dayAudio ? `audio-list-item active` : `audio-list-item non-active`}>
                  <div className="headphone-container">
                    <img src={headPhoneIcon} alt=""></img>
                  </div>
                  <div className="audio-text-container" onClick={() => selectSource(dayAudio, 0)}>
                    Audio of Day {day}
                  </div>
                  <div className="wave-container">
                    <img src={waveIcon} alt=""></img>
                  </div>
                </div>
              )}

              <div className={audioSource === shortAsking ? `audio-list-item active` : `audio-list-item non-active`}>
                <div className="headphone-container">
                  <img src={headPhoneIcon} alt=""></img>
                </div>
                <div className="audio-text-container" onClick={() => selectSource(shortAsking, dayAudio ? 0 : 1)}>
                  {t('audioplayer.title-short-asking')}
                </div>
                <div className="wave-container">
                  <img src={waveIcon} alt=""></img>
                </div>
              </div>
              <div className={audioSource === longAsking ? `audio-list-item active` : `audio-list-item non-active`}>
                <div className="headphone-container">
                  <img src={headPhoneIcon} alt=""></img>
                </div>
                <div className="audio-text-container" onClick={() => selectSource(longAsking, dayAudio ? 2 : 1)}>
                  {t('audioplayer.title-long-asking')}
                </div>
                <div className="wave-container">
                  <img src={waveIcon} alt=""></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="audio-player-day-right">
          <div className="audio-player-current">
            {/* progress bar */}
            <div className="progress-bar-container">
              <input type="range" className="progress-bar-tmp" defaultValue="0" ref={progressBar} onChange={changeRange} />
            </div>
            <div className="times">
              {/* current time */}
              <div className="current-time">{calculateTime(currentTime)}</div>
              {/* duration */}
              {duration && (
                <div className="duration">{duration && !isNaN(duration) && calculateTime(duration)}</div>
              )}
            </div>
          </div>
          <div className="audio-player-day-control">
            <button onClick={pevAudio} className='player-control back play-pause'>
              <img src={backIcon} alt="back" />
            </button>

            <button onClick={togglePlayPause} className='play-pause'>
              {isPlaying ? <img src={pauseIcon} alt="pause" /> : <img src={playIcon} alt="play" />}
            </button>

            <button onClick={nextAudio} className='player-control back play-pause'>
              <img src={forwardIcon} alt="back" />
            </button>

          </div>
        </div>
      </div>
    </>
  )
};

export default AudioPlayerDay;