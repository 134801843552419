import {
  SET_LANGUAGE, SET_MENU, SHOW_IND_REG_FORM
} from '../constants/appDataConstants';


export const appDataReducer = (state = {
  loading: false, lang: 'en', showIndRegForm: false
}, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { state, lang: action.payload }
    case SET_MENU:
      return { state, selectedMenu: action.payload }
    case SHOW_IND_REG_FORM:
      return { state, showIndRegForm: !state.showIndRegForm }
    default:
      return state;
  }
}