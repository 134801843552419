import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import cookieIcon from '../../images/cookie/cookie.png';

const CookieAlert = (props) => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([]);
  const cookieName = "theasking_acceptcookies";
  const [visible, setVisible] = useState(true);

  let CookieDate = new Date();
  CookieDate.setFullYear(CookieDate.getFullYear() + 1);
  var expiryDate = new Date(Number(new Date()) + (3600 * 1000 * 24 * 365 * 1));

  const cookieOptions = {
    path: '/',
    expires: expiryDate
  }

  const acceptCookie = () => {
    setCookie(cookieName, "1", cookieOptions);
    setVisible(false);
  }

  useEffect(() => {
    if (cookies[cookieName] && cookies[cookieName] === "1") {
      setVisible(false);
    } else {
      setVisible(props.showCookieAlert);
    }

  }, [cookies, props]);


  if (!visible) { return null; }
  return (
    <div className="cookieconsent-container">
      <div className="cookieconsent-text-container">
        <div className="cookie-container">
          <div className="cookie-icon">
            <img src={cookieIcon} alt="cookie"></img>
          </div>
          <div className="cookie-text">
            <div className="cookie-title">{t('cookieconsent.title')}</div>
            <div className="cookie-desc">{t('cookieconsent.text')}</div>
          </div>
        </div>
      </div>
      <div className="cookieconsent-button-container">
        <Link to="/cookies" target="_blank" rel="noopener noreferrer">
          <button className="cookieconsent-button-details">{t('cookieconsent.buttons.details')}</button>
        </Link>

        <button className="cookieconsent-button-accept" onClick={acceptCookie}>{t('cookieconsent.buttons.accept')}</button>
      </div>
    </div>
  );
};

export default CookieAlert;