import React from 'react';
import { getText } from '../../utils/htmhelper';
import { useTranslation } from 'react-i18next';
import phraseImg from '../../images/days/phrase.png';
import forwardImg from '../../images/days/go_forward.png';
import backImg from '../../images/days/go_back.png';
import reminderImg from '../../images/days/book_pencil.png';
import { Link } from 'react-router-dom';
import AudioPlayerDay from '../general/AudioPlayerDay';

const Day = (props) => {
  const { daynumber, texts } = props;

  const dayAudio = texts ? texts.acf.day_audio : null;
  const shortAsking = texts ? texts.acf.short_audio : null;
  const longAsking = texts ? texts.acf.audio2 : null;

  const { t } = useTranslation();
  const prevDay = parseInt(daynumber) > 0 ? parseInt(daynumber) - 1 : null;
  const nextDay = parseInt(daynumber) < 7 ? parseInt(daynumber) + 1 : 8;

  return (
    <div className={`day-container day-container-${daynumber}`}>
      <div className="day-header">
        <div className="day-label" style={{ backgroundImage: `url(${texts && texts.acf.title_bg})` }}>
          <div className="day-label-inner"
            style={{ color: texts && texts.acf.title_color ? texts.acf.title_color : `inherit` }} >
            {texts && texts.acf.day_title}
          </div>
        </div>
        <div className="day-title"
          dangerouslySetInnerHTML={texts && getText(texts.acf.title)}>
        </div>
      </div>
      <div className="day-intro"
        dangerouslySetInnerHTML={texts && getText(texts.acf.text1)}>
      </div>
      <div className="audio-player-container">
        <div className="audio-player">
          {texts && <AudioPlayerDay
            day={daynumber}
            dayAudio={dayAudio}
            shortAsking={shortAsking}
            longAsking={longAsking}
          ></AudioPlayerDay>}
        </div>
      </div>
      <div className="day-text-container">
        <div className="day-text-inner"
          dangerouslySetInnerHTML={texts && getText(texts.acf.text2)}>
        </div>
      </div>
      <div className="phrase-container">
        <div className="phrase-inner">
          <div className="phrase-img">
            <img src={phraseImg} alt=""></img>
          </div>
          <div className="phrase-text-container">
            <div className="phrase-title">
              {t('day.inspiring_phrase')}
            </div>
            <div className="phrase-text"
              dangerouslySetInnerHTML={texts && getText(texts.acf.phrase)}>
            </div>
          </div>
        </div>
      </div>
      <div className="reminder-container">
        <div className="reminder-inner">
          <div className="reminder-title">
            {t('day.reminder_title')}
          </div>
          <div className="reminder-text-container"
            style={{ background: `${texts && texts.acf.color}` }}
          >
            <div className="reminder-text-inner">
              <div className="reminder-image">
                <img src={reminderImg} alt=""></img>
              </div>
              <div className="reminder-text"
                style={{ color: `${texts && texts.acf.dont_forget_text_color}` }}>
                {t("day.reminder_text")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navigation-container">
        <div className="navigation-inner">
          {prevDay !== null &&
            (<Link to={`/day/${prevDay}`}>
              <div className="navigation-back">
                <div><img src={backImg} alt="<-"></img></div>
                <div>{t('day.forward')} {prevDay}</div>
              </div>
            </Link>)
          }
          {nextDay != null && nextDay !== 8 && (
            <Link to={`/day/${nextDay}`}>
              <div className="navigation-forward">
                <div><img src={forwardImg} alt="->"></img></div>
                <div>{t('day.forward')} {nextDay}</div>
              </div>
            </Link>)
          }
          {nextDay === 8 && (
            <Link to='/conclusions'>
              <div className="navigation-forward">
                <div><img src={forwardImg} alt="->"></img></div>
                <div>Conclusions</div>
              </div>
            </Link>)
          }
        </div>
      </div>
    </div>

  );
};

export default Day;