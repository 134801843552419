import React from 'react';
import AskingPerson from '../../images/individual/asking_person.png';
import { getText } from '../../utils/htmhelper';
import AudioPlayer from '../general/AudioPlayer';

const Header = (props) => {

  const { texts } = props;
  const audioFile = texts ? [texts.acf.audio_file] : [];

  return (
    <div className="ind-header-container">
      <div className="ind-header-top">
        <div className="left">
          <div className="title">
            {texts && texts.acf.title}
          </div>
          <div className="subtitle">
            {texts && texts.acf.subtitle}
          </div>
          <div className="welcome">
            {texts && texts.acf.welcome}
          </div>
          <div className="intro">
            {texts && texts.acf.intro}
          </div>
        </div>
        <div className="right">
          <img src={AskingPerson} alt="asking"></img>
        </div>
      </div>
      <div className="player">
        <div className="player-title">
          {texts && texts.acf.playertitle}
        </div>
        <div className="audio-player">
          {texts && (
            <AudioPlayer
              isMultiple={false}
              audiofiles={audioFile}>
            </AudioPlayer>
          )}
        </div>
      </div>
      <div className="grouptext">
        <div className='group1'
          dangerouslySetInnerHTML={texts && getText(texts.acf.grouptext1)}>
        </div>
        <div className='group2'>
          {texts && texts.acf.grouptext2}
        </div>
      </div>
    </div>
  );
};

export default Header;