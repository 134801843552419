import React, { useEffect, useState } from 'react';
import { activateSubscriber, getPostsFromCategory } from '../clientwrapper';
import { getHtmlText } from '../utils/htmhelper';
import logo1 from '../images/subscription/logo1.png';
import lightCircle1 from '../images/subscription/light_circle_1.png';

const ActivateRegistration = (props) => {

  const [mounted, setMounted] = useState(true);
  const [activated, setActivated] = useState(null);
  const [texts, setTexts] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_SUBSCRIPTION);
      const { data } = apiResult;
      const filteredText = data[0];
      setTexts(filteredText);
    };

    const compActivateSubscriber = async () => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const userToken = params.get('nk');
      if (userToken == null) {
        setActivated(false);
        return;
      }
      const userEmail = params.get('e');
      if (userEmail == null) {
        setActivated(false);
        return;
      }
      
      if (userToken !== '' && userEmail !== '') {
        const apiResult = await activateSubscriber(userEmail, userToken);
        const { success } = apiResult;
        if (success) {
          setActivated(true);
        } else {
          setActivated(false);
        }
      } else {
        setActivated(false);
      }
    };
    if (mounted) {
      compActivateSubscriber();
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);


  return (
    <div className="activate-registration-container">
      <div className="circle-container">
        <img src={lightCircle1} alt=''></img>
      </div>
      {activated != null && activated &&
        (
          <>
            <div className="subscription-title" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.ind_confirm_registration_success_title)}></div>
            <div className="subscription-text" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.ind_confirm_registration_success_text)}></div>
          </>
        )

      }
      {activated != null && !activated &&
        (
          <>
            <div className="subscription-title" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.confirm_registration_error_title)}></div>
            <div className="subscription-text" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.confirm_registration_error_text)}></div>
          </>
        )
      }
      <div className="logo-container">
        <img src={logo1} alt="logo"></img>
      </div>
    </div>
  );
};

export default ActivateRegistration;