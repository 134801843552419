import React from 'react';
import womanImage from '../../images/homepage/womenaudiogreen.png';
import { formatText } from '../../utils/htmhelper';
import AudioPlayerDay from '../general/AudioPlayerDay';
import lineImg from '../../images/asking-audio/line.png';

function AskingAudio(props) {

  const { texts } = props;
  const listItems = texts && texts.acf.list ? formatText(texts.acf.list).split('<br>') : [];
  const daynumber = 0;
  const dayAudio = null;
  const shortAsking = texts ? texts.acf.asking_audio2 : null;
  const longAsking = texts ? texts.acf.asking_audio1 : null;

  return (
    <div id="audio" ref={props.refProp} className="asking-audio-container asking-audio-vertical">
      <div className="asking-audio-center">
        <div className="left-side">
          <div className="asking-audio-title">
            {texts && texts.acf.title}
          </div>
          <div className="asking-audio-subtitle">
            {texts && texts.acf.subtitle}
          </div>
          <div className="asking-audio-text1">
            {texts && texts.acf.text_1}
          </div>
          <div className="asking-audio-text2">
            {texts && texts.acf.text_2}
          </div>
          <div className="asking-audio-list">
            <ol>
              {listItems.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ol>
          </div>
        </div>
        <div className="right-side">
          <img src={womanImage} alt="audio"></img>
        </div>
      </div>
      <div className="audio-player audio-player-homepage">
        {texts && <AudioPlayerDay
          day={daynumber}
          dayAudio={dayAudio}
          shortAsking={shortAsking}
          longAsking={longAsking}
        ></AudioPlayerDay>}

        <div className="below-audioplayer">
          <div className="below-audioplayer-1">
            {texts && texts.acf.below_audioplayer_1}
          </div>
          <div className="below-audioplayer-separator">
            <img src={lineImg} alt=""></img>
          </div>
          <div className="below-audioplayer-2">
            {texts && texts.acf.below_audioplayer_2}
          </div>
        </div>

      </div>
      <div className="asking-audio-footer">
        {texts && texts.acf.footer}
      </div>
    </div>
  );
}

export default AskingAudio;