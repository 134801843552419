import React, { useEffect, useState } from 'react';
import { getPostById } from '../../clientwrapper';
import DaySelector from '../individual/DaySelector';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { getAnimationParams } from '../../utils/animationhelper';

const Program = (props) => {

  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const animParams = getAnimationParams('program');

  const dayrow1 = [0, 1, 2, 3];
  const dayrow2 = [4, 5, 6, 7];

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostById(1529);
      const { data, success } = apiResult;
      if (success) {
        setTexts(data[0]);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <AnimatePresence exitBeforeEnter>
      {props.showProgram && (
        <motion.div
          transition={animParams['transition']}
          initial={animParams['initial']}
          animate={animParams['animate']}
          exit={animParams['animExit']}
          className="program-container-modal"
        >
          <div className="program-container-modal-close" onClick={props.toggleProgram}>X</div>
          <div className="ind-program-container">
            <div className="days-container">
              <div className="row-1">
                {dayrow1.map((item, i) => (
                  <Link to={`/day/${i}`} key={item} onClick={props.toggleProgram}>
                    <DaySelector
                      item={item}
                      texts={texts && texts.acf['day' + item]}>
                    </DaySelector>
                  </Link>
                ))}
              </div>
              <div className="row-2">
                {dayrow2.map((item, i) => (
                  <Link to={`/day/${parseInt(i) + 4}`} key={item} onClick={props.toggleProgram}>
                    <DaySelector
                      item={item}
                      texts={texts && texts.acf['day' + item]}>
                    </DaySelector>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </motion.div >
      )}

    </AnimatePresence>
  );
};

export default Program;