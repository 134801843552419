import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import Header from '../components/group/Header';
import InternationalProgram from '../components/group/InternationalProgram';
import TheProgram from '../components/group/TheProgram';
import WhatYouGet from '../components/group/WhatYouGet';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';
import UpcomingEvents from '../components/group/UpcomingEvents';

const GroupProgram = (props) => {

  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const animParams = getAnimationParams('page');
  const [events, setEvents] = useState(null);
  const [nextEvent, setNextEvent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_GROUPPROGRAM);
      const { data, success } = apiResult;
      if (success) {
        let filteredText = [];
        data.forEach((datatext) => {
          filteredText[datatext.title.rendered] = datatext;
        })
        setTexts(filteredText);
      }
    };

    const fetchEvents = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_GROUPEVENTS);
      const { data, success } = apiResult;
      if (success) {
        setEvents(data);
        // get the next event
        let nextEvent = { acf: { begin: "3000" } };
        data.forEach((event) => {
          let beginDate = event.acf.begin;
          if (beginDate < nextEvent.acf.begin) {
            nextEvent = event;
          }
        });
        setNextEvent(nextEvent);
      }
    };

    if (mounted) {
      fetchData();
      fetchEvents();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <>
      <motion.div
        transition={animParams['transition']}
        initial={animParams['initial']}
        animate={animParams['animate']}
        exit={animParams['animExit']}
        className="main-content"
      >
        <Header texts={texts && texts['Groupheader']}></Header>
        <WhatYouGet texts={texts && texts['Groupwhatyouget']} nextEvent={nextEvent}></WhatYouGet>
        <TheProgram texts={texts && texts['Grouptheprogram']}></TheProgram>
        <InternationalProgram texts={texts && texts['Groupinternational']}></InternationalProgram>
        <UpcomingEvents texts={texts && texts['Groupupcomingprogram']} events={events} nextEvent={nextEvent} setNextEvent={setNextEvent}></UpcomingEvents>
      </motion.div>
    </>
  );
};

export default GroupProgram;