import React from 'react';
import EventsList from '../components/events/EventsList';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

const EventsPage = (props) => {
  const animParams = getAnimationParams('page');
  
  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <div className="events-container">
        <div className="events-title">
          <span className="events-title-1">Upcoming</span> <span className="events-title-2">Events</span>
        </div>
        <div className='events-list-container'>
          <EventsList></EventsList>
        </div>
      </div>
    </motion.div>
  );
};

export default EventsPage;