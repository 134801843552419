import React from 'react';
import { formatText, getText } from '../../utils/htmhelper';
import forwardImg from '../../images/days/go_forward.png';
import backImg from '../../images/days/go_back.png';
import step1Img from '../../images/day0/step1.png';
import step2Img from '../../images/day0/step2.png';
import step3Img from '../../images/day0/step3.png';
import listSeparator from '../../images/day0/list_separator.png';
import giftImage from '../../images/day0/gift.png';
import importantImage from '../../images/day0/important.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AudioPlayerDay from '../general/AudioPlayerDay';
import AudioPlayer from '../general/AudioPlayer';

const DayZero = (props) => {
  const { texts } = props;
  const { t } = useTranslation();
  const nextDay = 1;
  const prevDay = "individual";

  const daynumber = 0;
  const dayAudio = null;
  const shortAsking = texts ? texts.acf.short_asking : null;
  const longAsking = texts ? texts.acf.audio_file : null;
  const audioFile = texts ? [texts.acf.day_zero_audio] : [];

  const listItems = texts && texts.acf.list3_items
    ? formatText(texts.acf.list3_items).split('<br>') : [];
  return (
    <>
      <div className="day-container">
        <div className="day-header">
          <div className="day-label" style={{ backgroundImage: `url(${texts && texts.acf.title_bg})` }}>
            <div className="day-label-inner"
              style={{ color: texts && texts.acf.title_color ? texts.acf.title_color : `inherit` }} >
              {texts && texts.acf.label}
            </div>
          </div>
        </div>
        <div className="day0-intro">
          <div className="day0-title">
            {texts && texts.acf.title}
          </div>
          <div className="day0-text">
            {texts && texts.acf.text1}
          </div>
        </div>

        <div className="day0-top-player">
          <div className="player">
            <div className="player-title">
              {t('day.day0_audio_title')}
            </div>
            <div className="audio-player">
              {texts && (
                <AudioPlayer
                  isMultiple={false}
                  audiofiles={audioFile}>
                </AudioPlayer>
              )}
            </div>
          </div>
        </div>

        <div className="day0-list">
          <div className="list-counter">1.</div>
          <div className="list-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.list1)}>
          </div>
        </div>

      </div>
      <div className="practice-container">
        <div className="practice-inner">
          <div className="practice-title">
            {texts && texts.acf.practice_title}
          </div>
          <div className="practice-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.practice_explanation)}>
          </div>
          <div className="steps-container">
            <div className="step">
              <div className="step-image">
                <img src={step1Img} alt=""></img>
              </div>
              <div className="step-text">
                {texts && texts.acf.step1}
              </div>
            </div>
            <div className="step">
              <div className="step-image">
                <img src={step2Img} alt=""></img>
              </div>
              <div className="step-text">
                {texts && texts.acf.step2}
              </div>
            </div>
            <div className="step">
              <div className="step-image">
                <img src={step3Img} alt=""></img>
              </div>
              <div className="step-text">
                {texts && texts.acf.step3}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="audio-player-container day0-audio-player">
        <div className="audio-player">
          {texts && <AudioPlayerDay
            day={daynumber}
            dayAudio={dayAudio}
            shortAsking={shortAsking}
            longAsking={longAsking}
          ></AudioPlayerDay>}
        </div>
        <div className="below-player">
          {texts && texts.acf.below_player}
        </div>
      </div>
      <div className="day-container day-container-day0-bottom">
        <div className="day0-list">
          <div className="list-counter">2.</div>
          <div className="list-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.list2)}>
          </div>
        </div>
        <div className="day0-list">
          <div className="list-counter">3.</div>
          <div className="list-text">
            <div
              dangerouslySetInnerHTML={texts && getText(texts.acf.list3)}>
            </div>
            <div className="list-list">
              {listItems.map((item, i) => (
                <div className="list-list-item" key={i}>
                  <img src={listSeparator} alt=""></img><div>{item}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="day0-list">
          <div className="list-counter">4.</div>
          <div className="list-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.list4)}>
          </div>
        </div>
        <div className="day0-footer-container">
          <div className="day0-footer-inner">
            <div className="footer-top">
              <img src={giftImage} alt=""></img>
              <div dangerouslySetInnerHTML={texts && getText(texts.acf.footer)}>
              </div>
            </div>
            <div className="footer-bottom">
              {texts && texts.acf.next_day}
            </div>
          </div>
        </div>
        <div className="navigation-container day0">
          <div className="navigation-inner">
            {prevDay !== null &&
              (<Link to={`/${prevDay}`}>
                <div className="navigation-back">
                  <div><img src={backImg} alt="<-"></img></div>
                  <div>{t('day.back')}</div>
                </div>
              </Link>)
            }
            {nextDay != null && (
              <Link to={`/day/${nextDay}`}>
                <div className="navigation-forward">
                  <div><img src={forwardImg} alt="->"></img></div>
                  <div>{t('day.forward')} {nextDay}</div>
                </div>
              </Link>)
            }
          </div>
        </div>
        <div className="page-bottom-container">
          <div className="page-bottom-inner">
            <div className="page-bottom-image"><img src={importantImage} alt=""></img></div>
            <div className="page-bottom-text">{texts && texts.acf.page_bottom}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DayZero;