import React from 'react';
import backgroundImage from '../../images/asking-video/background.png';
import extraLineG from '../../images/asking-video/extra-line.png';
import whiteCircle from '../../images/asking-video/circle.png';
import ReactPlayer from "react-player";
import { getText } from '../../utils/htmhelper';

function AskingVideo(props) {

  const { texts } = props;

  return (
    <div id="video" ref={props.refProp} className="asking-video-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="asking-video-header-container">
        <div className="left">
          <div className="asking-video-title">{texts && texts.acf.title}</div>
          <div className="asking-video-subtitle">{texts && texts.acf.subtitle}</div>
        </div>
        <div className="right">
          <div className="asking-video-intro">{texts && texts.acf.intro}</div>
          <div className="right-extra">
            <div className="asking-video-extra-g"><img src={extraLineG} alt=""></img></div>
            <div className="asking-video-extra">{texts && texts.acf.intro_extra_line}</div>
          </div>
        </div>
      </div>
      <div className="asking-video-video">
        <ReactPlayer className='react-player'
          url="https://www.youtube.com/watch?v=AkFhifScceU"
          config={{
            youtube: {
              playerVars: { "cc_load_policy": 1, "controls": 1 }
            }
          }}
        />
      </div>
      <div className="asking-video-footer-container" dangerouslySetInnerHTML={texts && getText(texts.acf.footer)} />
      <div className="circle">
        <img src={whiteCircle} alt="" />
      </div>
    </div >
  );
}

export default AskingVideo;