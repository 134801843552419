import React from 'react';
import { getText } from '../../utils/htmhelper';
import messageLogo from '../../images/silo/message_logo.png';
import circle from '../../images/silo/circle.png';
import ReactPlayer from 'react-player';

const TheGift = (props) => {
  const { texts } = props;
  return (
    <div className="thegift-container">
      <div className="gift-title" dangerouslySetInnerHTML={texts && getText(texts && texts.acf.title)}>
      </div>
      <div className="gift-text1"
        dangerouslySetInnerHTML={texts && getText(texts.acf.text1)}>
      </div>
      <div className="gift-video-container">
        <ReactPlayer className='react-player'
          url="https://www.youtube.com/watch?v=-cSojW7y6wo"
          config={{
            youtube: {
              playerVars: { "cc_load_policy": 1, "controls": 1 }
            }
          }}
        />

      </div>
      <div className="gift-text2"
        dangerouslySetInnerHTML={texts && getText(texts.acf.text2)}>
      </div>
      {texts && texts.acf.image_middle && (
        <div className="gift-image-container">
          <img src={texts.acf.image_middle} alt=""></img>
        </div>
      )}

      <div className="message-container">
        <div className="message-title">
          {texts && texts.acf.messagetitle}
        </div>
        <div className="message-quote-container" style={{ backgroundImage: `url(${circle})` }} >
          <div className="message-quote"
            dangerouslySetInnerHTML={texts && getText(texts.acf.message_quote)}>
          </div>
        </div>
        <div className="message-text"
          dangerouslySetInnerHTML={texts && getText(texts.acf.message_text)}>
        </div>
        <div className="message-logo">
          <a href="https://silosmessage.net/" target="_blank" rel="noreferrer"><img src={messageLogo} alt=""></img></a>
        </div>
      </div>
    </div>
  );
};

export default TheGift;