import React, { useState } from 'react';
import personImage from '../../images/howitworks/person.png';
import heartImage from '../../images/howitworks/heart.png';
import arrowImage from '../../images/howitworks/arrow.png';
import individualIcon from '../../images/howitworks/icon_individual.png';
import testimonyBg from '../../images/howitworks/testimony_bg.png';
import groupIcon from '../../images/howitworks/icon_group.png';
import { formatText, getText } from '../../utils/htmhelper';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function HowItWorks(props) {

  const { t } = useTranslation();
  const { texts } = props;
  const [showMore, setShowMore] = useState(false);

  const listItems = texts && texts.acf.text_2 ? formatText(texts.acf.text_2).split('<br>') : [];
  const individualListItems = texts && texts.acf.individual_list ? formatText(texts.acf.individual_list).split('<br>') : [];
  const groupListItems = texts && texts.acf.group_list ? formatText(texts.acf.group_list).split('<br>') : [];
  const text1parts = texts && texts.acf.text_1 && texts.acf.text_1.split('<rm/>');

  return (
    <>
      <div id="howitworks" ref={props.refProp} className="howitworks-container">
        <div className="howitworks-title"
          dangerouslySetInnerHTML={texts && getText(texts.acf.title)}>
        </div>
        <div className="howitworks-subtitle">
          {texts && texts.acf.subtitle}
        </div>
        <div className="howitworks-part1-container">
          <div className="part1-img">
            <img src={personImage} alt=""></img>
          </div>
          <div className="part1-text">
            <div dangerouslySetInnerHTML={texts && getText(text1parts[0])}></div>
            <div className="readmore" onClick={() => setShowMore(!showMore)}>{t('general.readmore')}</div>
            {showMore && <div dangerouslySetInnerHTML={texts && getText(text1parts[1])}></div>}
          </div>

        </div>
        <div className="howitworks-part1-list-container">
          {listItems.map((item, i) => (
            <div className="howitworks-list" key={i}>
              <div className="list-separator">
                <img src={heartImage} alt=""></img>
              </div>
              <div className="list-text" dangerouslySetInnerHTML={getText(item)}>
              </div>
            </div>
          ))}
        </div>
        <div className="arrow-down-container">
          <img src={arrowImage} alt=""></img>
        </div>
      </div>
      <div id="howitworks-2" className="howitworks-2-container">
        <div className="howitworks-2-testimony-container">
          <div className="howitworks-2-testimony-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.testimony)}
            style={{ backgroundImage: `url(${testimonyBg})` }}>
          </div>
          <div className="howitworks-2-testimony-image">
            {texts && texts.acf.testominy_image.url && (
              <img src={texts.acf.testominy_image.url} alt=''></img>
            )}
          </div>
        </div>
        <div className="howitworks-2-header">
          <div>
            <span className="howitworks-2-header1">
              {texts && texts.acf.text_3}
            </span>&nbsp;
            <span className="howitworks-2-header2">
              {texts && texts.acf.text_4}
            </span>
          </div>
          <div className="howitworks-2-header3">
            {texts && texts.acf.text_5}
          </div>
        </div>
        <div className="howitworks-2-join-container">
          <div className="howitworks-2-join-individual">
            <Link to="/individual">
              <div className="individual-button">
                <div>{t('program.individual')}</div>
                <div>{t('program.program')}</div>
              </div>
            </Link>
            <div className="individual-icon">
              <img src={individualIcon} alt=''></img>
            </div>
            <div className="individual-list">
              <ul>
                {individualListItems.map((item, i) => (
                  <li key={i}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="howitworks-2-join-group">
            <Link to="/group">
              <div className="group-button">
                <div>{t('program.group')} {t('program.program')}</div>
                <div>{t('program.online')}</div>
              </div>
            </Link>
            <div className="group-icon">
              <img src={groupIcon} alt=''></img>
            </div>
            <div className="group-list">
              <ul>
                {groupListItems.map((item, i) => (
                  <li key={i}>
                    {item}
                  </li>
                ))}
              </ul>

            </div>

          </div>

        </div>
      </div >
    </>
  );
}

export default HowItWorks;