import axios from 'axios';

const time = Date.now().toString();

const headers = {
  Accept: "application/json"
};

const apiClientZoho = axios.create(
  {
    baseURL: process.env.REACT_APP_ZOHO_API_URL,
    //adapter: cache.adapter,
    withCredentials: true,
    timeout: 60000,
    headers: headers
  }
);

export default apiClientZoho;