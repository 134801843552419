import React, { useCallback, useEffect, useRef, useState } from 'react';
import { checkInput } from '../../utils/validators';
import { useTranslation } from 'react-i18next';
import CountrySelector from '../utils/CountrySelector';
import fbIcon from '../../images/contact/facebook_icon.png';
import instaIcon from '../../images/contact/insta_icon.png';
import { getHtmlText } from '../../utils/htmhelper';
import { sendContactMessage, resetContact } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import MessageBox from '../utils/MessageBox';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import graphImage from '../../images/contact/asking_contact.png';

const Contact = (props) => {
  const { t } = useTranslation();
  const { texts } = props;
  const dispatch = useDispatch();
  const userContact = useSelector((state) => state.userContact);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');

  const { status, loading } = userContact;

  const Refs = {
    name: useRef(null),
    email: useRef(null),
    country: useRef(null),
    message: useRef(null)
  };

  const initialState = {
    email: '', name: '', country: { value: '', label: '' }, message: '', emailfrom: ''
  };

  const initialStateError = {
    email: '', name: '', country: '', message: '', emailfrom: ''
  };

  const [formData, setFormData] = useState(initialState);
  const [formDataError, setFormDataError] = useState(initialStateError);

  const [showMsg, setShowMsg] = useState('');

  const fieldsValidators = [
    { name: 'name', value: formData.name, validators: ['empty'] },
    { name: 'email', value: formData.email, validators: ['empty', 'email'] },
    { name: 'country', value: formData.country.value, validators: [] },
    { name: 'message', value: formData.message, validators: ['empty'] },
    { name: 'emailfrom', value: formData.emailfrom, validators: [] }
  ];

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked ? '1' : 0 : target.value;
    const name = target.name;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    const field = fieldsValidators.find(element => element.name === name);
    const referenceValue = '';
    const errorMsg = checkInput(
      { inputName: name, inputValue: value, referenceValue: referenceValue }, field.validators);
    setFormDataError(prevState => ({ ...prevState, [name]: errorMsg }));
  };

  const validateInput = () => {
    const hasError = fieldsValidators.some(field => {
      const referenceValue = null;
      const errorMsg = checkInput(
        { inputName: field.name, inputValue: field.value, referenceValue: referenceValue },
        field.validators);
      if (errorMsg) {
        setFormDataError(prevState => ({ ...prevState, [field.name]: errorMsg }));
        Refs[field.name].current.scrollIntoView(true);
        return true;
      }
      setFormDataError(prevState => ({ ...prevState, [field.name]: '' }));
      return false;
    });

    return hasError;
  };

  const setCountry = country => {
    setFormData(prevState => ({ ...prevState, country }));
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (token === null || token === '') {
      return;
    }
    if (formData.emailfrom !== '') {
      return;
    }
    const urlRE = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
    if (formData.message !== '' && formData.message.match(urlRE)) {
      return;
    }
    submitForm();
  };

  const submitForm = () => {
    setFormDataError(initialStateError);
    const isInvalid = validateInput();
    if (!isInvalid) {
      dispatch(sendContactMessage(
        formData.name, formData.country.label, formData.email, formData.message, formData.emailfrom
      ));
    }
  }

  const resetMsg = () => {
    dispatch(resetContact());
    setShowMsg('');
    // reset form values
    setFormData(initialState);
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      borderBottom: '1px solid $color-blue-1',
      boxShadow: 'none',
      borderRadius: '0px'
    })
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha('yourAction');
    setToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    if (token === '') {
      handleReCaptchaVerify();
    }
    if (status) {
      setShowMsg(status);
    }
  }, [handleReCaptchaVerify, props, status, showMsg, token]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA3_SITE_KEY}>
      {showMsg ?
        <MessageBox
          variant={status === 'error' ? 'danger' : 'info'}
          resetMsg={resetMsg}>
          {status === 'error' ? t('contact.messages.error') : t('contact.messages.success')}
        </MessageBox> : null
      }
      <div className="contact-container">
        <div className="contact-inner">
          <div className="contact-left">
            <div className="img-container" style={{ backgroundImage: `url(${graphImage})` }}></div>
          </div>
          <div className="contact-right">
            <div className="contact-title">
              {texts && texts.acf.title}
            </div>
            <div className="contact-content">
              <form noValidate onSubmit={(e) => onSubmit(e)}>
                <div className="hidden">
                  <input type="text" name="emailfrom" value={formData.emailfrom}
                    onChange={(e) => handleInputChange(e)}>
                  </input>
                </div>
                <div className="contact-row">
                  <div className="input-container input-name">
                    <div ref={Refs.name} className="scrollto"></div>
                    <div className="name-input">
                      <div className="input-label">
                        {t('contact.fullname')}
                      </div>
                      <div className="input-input">
                        <input formNoValidate
                          value={formData.name}
                          className={`input ${formDataError.name ? "inputerror" : ""}`}
                          type="text" name="name" onChange={(e) => handleInputChange(e)}
                        />
                        {formDataError.name && <div className='error'>
                          {t('contact.errors.' + formDataError.name)}
                        </div>}
                      </div>
                    </div>
                  </div>

                  <div className="input-container input-country">
                    <div ref={Refs.country} className="scrollto"></div>
                    <div className="name-input">
                      <div className="input-label">
                        {t('contact.country')}
                      </div>
                      <div className="input-input">
                        <CountrySelector setValue={setCountry}
                          customStyles={customStyles} value={formData.country}
                        ></CountrySelector>
                        {formDataError.country &&
                          <div className='error'>
                            {t('contact.errors.' + formDataError.country)}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contact-row">

                  <div className="input-container input-email small">
                    <div ref={Refs.email} className="scrollto"></div>
                    <div className="name-input">
                      <div className="input-label">
                        {t('contact.email')}
                      </div>
                      <div className="input-input">
                        <input formNoValidate
                          value={formData.email}
                          className={`input ${formDataError.email ? "inputerror" : ""}`}
                          type="email" name="email" onChange={(e) => handleInputChange(e)}
                        />
                        {formDataError.email && <div className='error'>
                          {t('contact.errors.' + formDataError.email)}
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contact-row">

                  <div className="input-container input-message small">
                    <div ref={Refs.message} className="scrollto"></div>
                    <div className="name-input">
                      <div className="input-label">
                        {t('contact.message')}
                      </div>
                      <div className="input-input">
                        <textarea formNoValidate
                          value={formData.message}
                          className={`input ${formDataError.message ? "inputerror" : ""}`}
                          type="text" name="message" onChange={(e) => handleInputChange(e)}
                          rows="5"
                        />
                        {formDataError.message && <div className='error'>
                          {t('contact.errors.' + formDataError.message)}
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contact-row">
                  <div className="input-container">
                    <div className="form-buttons-container">
                      <button className="button" disabled={loading}>
                        {t('contact.send')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="follow-container">
              <div className="follow-inner">
                <div className="follow-title">
                  {texts && texts.acf.follow_title}
                </div>
                <div className="follow-text">
                  {texts && texts.acf.follow_text}
                </div>
                <div className="follow-icons">
                  { t('social.facebook') !== '' && 
                    <a href={t('social.facebook')} rel="noreferrer" target="_blank">
                      <img src={fbIcon} alt="fb"></img>
                    </a>
                  }
                  { t('social.instagram') !== '' && 
                    <a href={t('social.instagram')} rel="noreferrer" target="_blank">
                      <img src={instaIcon} alt="insta"></img>
                    </a>
                  }
                  {/*
                  <a href="https://twitter.com" rel="noreferrer" target="_blank">
                    <img src={twIcon} alt="twitter"></img>
                  </a>
                  */}
                </div>
              </div>
            </div>
            <div className="contact-footer-container">
              <div className="contact-footer-inner">
                <div className="footer-title">
                  {texts && texts.acf.asking_title}
                </div>
                <div className="footer-text"
                  dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.asking_text)}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default Contact;