import React from 'react';
import tileBg from '../../images/whatsnext/title_bg.png';
import { getText } from '../../utils/htmhelper';
const Header = (props) => {

  const { texts } = props;

  return (
    <div className="wn-header-container">

      <div className="youdidit" style={{ backgroundImage: `url(${tileBg})` }}>
        {texts && texts.acf.youdidit}
      </div>
      <div className="title">
        {texts && texts.acf.title}
      </div>
      <div className="intro" dangerouslySetInnerHTML={texts && getText(texts.acf.intro)}>
      </div>
      <div className="wn-h-footer-container">
        <div className="wn-h-footer" dangerouslySetInnerHTML={texts && getText(texts.acf.footer)}>
        </div>
      </div>
    </div>
  );
};

export default Header;