import React, { useState, useEffect } from 'react';
import { getPostsFromSubCategories } from '../clientwrapper';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';
import FAQCategory from '../components/faq/FAQCategory';
import { useTranslation } from 'react-i18next';

function FAQPage(props) {
  const { t } = useTranslation();

  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const faqPosts = await getPostsFromSubCategories(process.env.REACT_APP_CATEGORY_FAQ);
      setTexts(faqPosts);
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <div className="faq-container">
        <div className="faq-title hidden">
          {t('faq.title')}
        </div>
        <div className="faqs-container">
          {/* iterate on each category */}
          {texts && Object.entries(texts).map(([categoryName, categoryPosts]) => (
            <FAQCategory name={categoryName} posts={categoryPosts} key={categoryName}></FAQCategory>
          ))}
        </div>
      </div>
    </motion.div>
  );
}
export default FAQPage;