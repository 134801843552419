import React, { useState, useEffect } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import Members from '../components/ourteam/Members';
import Origins from '../components/ourteam/Origins';
import OurTeam from '../components/ourteam/OurTeam';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

const TeamPage = (props) => {
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState([]);
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_TEAM);
      const { data } = apiResult;
      let filteredText = [];
      data.forEach((datatext) => {
        filteredText[datatext.title.rendered] = datatext;
      })
      setTexts(filteredText);
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <Origins texts={texts['Ourteamorigins']}></Origins>
      <OurTeam texts={texts['Ourteamteam']}></OurTeam>
      <Members texts={texts['Ourteammembers']}></Members>
    </motion.div>
  );
};

export default TeamPage;