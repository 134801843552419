import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { appDataReducer } from '../redux/reducers/appDataReducer';
import { contactReducer, subscribeReducer } from '../redux/reducers/userReducer';

const initialState = {};

const reducer = combineReducers({
  appData: appDataReducer,
  userContact: contactReducer,
  userSubscribe: subscribeReducer
})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;