import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';
import { getText } from '../utils/htmhelper';

const CreditPage = () => {

  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_CREDIT);
      const { data, success } = apiResult;
      if (success) {
        const filteredText = data[0];
        setTexts(filteredText);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <div className="credit-container">
        <div className="credit-title" dangerouslySetInnerHTML={texts && getText(texts.title.rendered)}></div>
        <div className="credit-content" dangerouslySetInnerHTML={texts && getText(texts.content.rendered)} ></div>
      </div>
    </motion.div>
  );
};

export default CreditPage;