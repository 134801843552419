import React from 'react';
import { getText } from '../../utils/htmhelper';
import DaySelector from './DaySelector';
import beginTitle from '../../images/individual/begin.png';
import clickImage from '../../images/individual/click.png';
import askingImage from '../../images/individual/asking.png';
import testimonyImage from '../../images/individual/testimony.png';
import wnArrow from '../../images/individual/whatsnextarrow.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toggleIndividualRegistrationForm } from '../../redux/actions/appDataActions';
import { useDispatch } from 'react-redux';


const Program = (props) => {
  const { texts } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dayrow1 = [0, 1, 2, 3];
  const dayrow2 = [4, 5, 6, 7];

  return (
    <div className="ind-program-container">
      <div className="journey">{texts && texts.acf.journey}</div>
      <div className="title" style={{ backgroundImage: `url(${beginTitle})` }}>
        <div dangerouslySetInnerHTML={texts && getText(texts.acf.title)}>
        </div>
        <div className="click-container">

          <div className="day-click-container">
            <div className="click" dangerouslySetInnerHTML={texts && getText(texts.acf.click)}></div>
            <div className="click-image">
              <img src={clickImage} alt=""></img>
            </div>
          </div>
          <span className="link" onClick={() => { dispatch(toggleIndividualRegistrationForm()) }}>
            <div className="reminder-click-container">
              {t('individual.getreminder')}
            </div>
          </span>

        </div>

      </div>

      <div className="days-container">
        <div className="row-1">
          {dayrow1.map((item, i) => (
            <Link to={`/day/${i}`} key={item}>
              <DaySelector
                item={item}
                texts={texts && texts.acf['day' + item]}>
              </DaySelector>
            </Link>
          ))}
        </div>
        <div className="row-2">
          {dayrow2.map((item, i) => (
            <Link to={`/day/${parseInt(i) + 4}`} key={item}>
              <DaySelector
                item={item}
                texts={texts && texts.acf['day' + item]}>
              </DaySelector>
            </Link>
          ))}
        </div>
      </div>
      <div className="feedback">
        <div className="feedback-text-container">
          <div className="feedback-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.feedback)}>
          </div>
        </div>
        <Link to={{
          pathname: `/conclusions`,
          backLink: 'individual',
          backText: t('whatsnext.backinidividual')
        }}>
          <div className="whatsnext-link-container">
            <div className="whatsnext-link-image">
              <img src={wnArrow} alt=""></img>
            </div>
            <div className="whatsnext-link-text">
              {t('individual.conclusions')}
            </div>
          </div>
        </Link>
        <Link to={{
          pathname: `/whatsnext`,
          backLink: 'individual',
          backText: t('whatsnext.backinidividual')
        }}>
          <div className="whatsnext-link-container">
            <div className="whatsnext-link-image">
              <img src={wnArrow} alt=""></img>
            </div>
            <div className="whatsnext-link-text">
              {t('individual.whatsnext')}
            </div>
          </div>
        </Link>
      </div>
      <div className="strip-container">
        <div className="strip"></div>
        <div className="asking-image">
          <img src={askingImage} alt=""></img>
        </div>
      </div>
      <div className="ind-testimony-container-bottom">
        <div className="testimony-text"
          dangerouslySetInnerHTML={texts && getText(texts.acf.testimony)}>
        </div>
        <div className="testimony-image">
          <img src={testimonyImage} alt=""></img>
        </div>
      </div>
    </div >
  );
};

export default Program;