import React, { useState, useEffect } from 'react';
import AskingVideo from '../components/homepage/AskingVideo';
import AskingAudio from '../components/homepage/AskingAudio';
import Testimony from '../components/homepage/Testimony';
import HowItWorks from '../components/homepage/HowItWorks';
import { getPostsFromCategory } from '../clientwrapper';
import Header from '../components/general/Header';
import Benefits from '../components/homepage/Benefits';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

function HomePage(props) {
  const { refs } = props;
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState({});
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_HOME);
      const { data, success } = apiResult;
      if (success) {
        let filteredText = [];
        data.forEach((datatext) => {
          filteredText[datatext.title.rendered] = datatext;
        })
        setTexts(filteredText);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <Header refs={refs} texts={texts['header']}></Header>
      <AskingVideo refProp={refs.videoRef} texts={texts['video']}></AskingVideo>
      <Benefits refProp={refs.benefitsRef} texts={texts['benefits']}></Benefits>
      <AskingAudio refProp={refs.audioRef} texts={texts['audio']}></AskingAudio>
      <HowItWorks refProp={refs.howitworksRef} texts={texts['howitworks']}></HowItWorks>
      <Testimony refProp={refs.testimonyRef} texts={texts['testimony']}></Testimony>
    </motion.div>
  );
}
export default HomePage;