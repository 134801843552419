import React from 'react';
import HeaderBg from '../../images/group/header_bg.png';
import Globe from '../../images/group/globe.png';
import { getText } from '../../utils/htmhelper';

const Header = (props) => {

  const { texts } = props;

  return (
    <div className="group-header-container">
      <div className="group-header-bg" style={{ backgroundImage: `url(${HeaderBg})` }}>
      </div>
      <div className="group-header-content">
        <div className="title-container">
          <div className="title1">
            {texts && texts.acf.title1}
          </div>
          <div className="title2">
            {texts && texts.acf.title2}
          </div>
          <div className="online">
            {texts && texts.acf.live_online}
          </div>
        </div>
        <div className="connect" dangerouslySetInnerHTML={texts && getText(texts.acf.connect)}></div>
        <div className="content" dangerouslySetInnerHTML={texts && getText(texts.acf.content)}></div>
        <div className="zoom" >
          <img src={Globe} alt=""></img>
          <div className="text">
            {texts && texts.acf.zoom}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;