
import {
  SET_LANGUAGE, SET_MENU, SHOW_IND_REG_FORM
} from '../constants/appDataConstants';


export const setUserLanguage = (lang) => (dispatch) => {
  dispatch({ type: SET_LANGUAGE, payload: lang });
}

export const setMenu = (menuItem) => (dispatch) => {
  dispatch({ type: SET_MENU, payload: menuItem });
}

export const toggleIndividualRegistrationForm = () => (dispatch, getState) => {
  dispatch({ type: SHOW_IND_REG_FORM, payload: '' });
}