import React from 'react';
import { dayimages } from '../individual/imageHelper'

const DaySelector = (props) => {
  const { item, texts } = props;
  const dayName = item === 0 ? 'Day Zero' : 'Day ' + item;

  return (
    <div className="day-selector-container">
      <div className={`day-title day-${item}`}
        style={{ backgroundImage: `url(${dayimages[item]})` }}>
        <p>{dayName}</p>
      </div>
      <div className="day-text">
        {texts && texts}
      </div>
    </div>
  );
};

export default DaySelector;