import React, { useState, useEffect } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import TheGift from '../components/silo/TheGift';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

const SiloPage = (props) => {
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState([]);
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_SILO);
      const { data } = apiResult;
      let filteredText = [];
      data.forEach((datatext) => {
        filteredText[datatext.title.rendered] = datatext;
      })
      setTexts(filteredText);
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <TheGift texts={texts['Silothegift']}></TheGift>
    </motion.div>
  );
};

export default SiloPage;