export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const USER_AUTH_REQUEST = 'USER_AUTH_REQUEST';
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const USER_AUTH_FAIL = 'USER_AUTH_FAIL';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';

export const USER_SIGNOUT_REQUEST = "USER_SIGNOUT_REQUEST";
export const USER_SIGNOUT_SUCCESS = "USER_SIGNOUT_SUCCESS";
export const USER_SIGNOUT_FAIL = "USER_SIGNOUT_FAIL";

export const USER_SUBSCRIBE_FAIL = 'USER_SUBSCRIBE_FAIL';
export const USER_SUBSCRIBE_SUCCESS = 'USER_SUBSCRIBE_SUCCESS';
export const USER_SUBSCRIBE_GROUP_SUCCESS = 'USER_SUBSCRIBE_GROUP_SUCCESS';
export const USER_SUBSCRIBE_REQUEST = 'USER_SUBSCRIBE_REQUEST';
export const USER_SUBSCRIBE_RESET = 'USER_SUBSCRIBE_RESET';

export const USER_CONTACT_FAIL = 'USER_CONTACT_FAIL';
export const USER_CONTACT_SUCCESS = 'USER_CONTACT_SUCCESS';
export const USER_CONTACT_REQUEST = 'USER_CONTACT_REQUEST';
export const USER_CONTACT_RESET = 'USER_CONTACT_RESET';

