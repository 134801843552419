import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import headerLogo from '../../images/logo/logo_top.png';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../general/LanguageSwitcher';
import OpenProgram from '../program/OpenProgram';

function Navbar(props) {
  const { t } = useTranslation();

  const [click, setClick] = useState(false);
  const [hover, setHover] = useState('');
  const [dropdown, setDropdown] = useState(null);

  const handleClick = () => {
    setClick(!click);
    setDropdown(null);
  }

  const closeMobileMenu = () => {
    setClick(false);
    setDropdown(null);
  }

  const openMenu = (itemname) => {
    dropdown === itemname ? setDropdown(null) : setDropdown(itemname);
    //closeMobileMenu();
  }
  const submenuClick = () => {
    setDropdown(null);
  }

  return (
    <>
      <div className='navbar-container'>
        <OpenProgram></OpenProgram>
        <nav className='navbar'>
          <Link to="/" onClick={closeMobileMenu}>
            <div className="navbar-brand-name flexrow">
              <img src={headerLogo} alt="logo"></img>
              <span className="page-title">{t('page.title')}</span>
            </div>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>

            {/* the program */}
            <li className={hover === "theprogram" || dropdown === 'theprogram'
              ? 'nav-item nav-item-open' : 'nav-item'}>
              <span className={hover === "theprogram" || dropdown === 'theprogram'
                ? 'nav-links-hover nav-links' : 'nav-links'}
                onClick={() => openMenu('theprogram')}
                onMouseEnter={() => setHover('theprogram')}
                onMouseLeave={() => setHover('')}
              >
                {t('navigation.theprogram')}
              </span>
              {dropdown === 'theprogram' && (
                <ul onClick={handleClick}
                  className={(dropdown === 'theprogram') ? 'dropdown-menu' : 'dropdown-menu clicked'}>
                  <li>
                    <Link className="dropdown-link" to='/individual' onClick={submenuClick}>
                      {t('navigation.individual')}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-link" to='/group' onClick={submenuClick}>
                      {t('navigation.group')}
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* events */}
            <li className='nav-item'>
              <Link to="/events" onClick={closeMobileMenu}>
                <span className='nav-links'>
                  {t('navigation.events')}
                </span>
              </Link>
            </li>

            {/* about us */}
            <li className='nav-item'>
              <span className={hover === "aboutus" || dropdown === 'aboutus'
                ? 'nav-links-hover nav-links' : 'nav-links'}
                onClick={() => openMenu('aboutus')}
                onMouseEnter={() => setHover('aboutus')}
                onMouseLeave={() => setHover('')}
              >
                {t('navigation.aboutus')}
              </span>
              {dropdown === 'aboutus' && (
                <ul onClick={handleClick}
                  className={(dropdown === 'aboutus') ? 'dropdown-menu' : 'dropdown-menu clicked'}>
                  <li>
                    <Link className="dropdown-link" to='/silothegift' onClick={submenuClick}>
                      {t('navigation.thegift')}
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-link" to='/ourteam' onClick={submenuClick}>
                      {t('navigation.team')}
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {/* contact */}
            {/* 
            <li className='nav-item'>
              <Link to="/contact" onClick={closeMobileMenu}>
                <span className='nav-links'
                  onClick={() => openMenu('')}>
                  {t('navigation.contact')}
                </span>
              </Link>
            </li>
            */}

            {/* faq */}
            <li className='nav-item'>
              <Link to="/faq" onClick={closeMobileMenu}>
                <span className='nav-links'>
                  {t('navigation.faq')}
                </span>
              </Link>
            </li>

            {/* whatsnext */}
            <li className='nav-item'>
              <Link to="/whatsnext" onClick={closeMobileMenu}>
                <span className='nav-links'
                  onClick={() => openMenu('')}>
                  {t('navigation.whatsnext')}
                </span>
              </Link>
            </li>

            {/* news */}
            <li className='nav-item tmphidden'>
              <div className='nav-links'>
                {t('navigation.news')}
              </div>
            </li>
            {/* languages */}
            <li className='nav-item tmphidden'>
              <span className={hover === "language" || dropdown === 'language'
                ? 'nav-links-hover nav-links' : 'nav-links'}
                onClick={() => openMenu('language')}
                onMouseEnter={() => setHover('language')}
                onMouseLeave={() => setHover('')}
              >
                {t('navigation.language')}
              </span>
              {dropdown === 'language' && (
                <ul onClick={handleClick}
                  className={(dropdown === 'language') ? 'dropdown-menu left70' : 'dropdown-menu clicked'}>
                  <li>
                    <div className="dropdown-link">
                      {t('navigation.en')}
                    </div>
                  </li>
                  <li>
                    <div className="dropdown-link">
                      {t('navigation.de')}
                    </div>
                  </li>
                  <li>
                    <div className="dropdown-link">
                      {t('navigation.hu')}
                    </div>
                  </li>
                </ul>
              )}
            </li>
            <li className="mobil-lang nav-item">
              <div className="mobil-lang-container flexrow"><LanguageSwitcher></LanguageSwitcher></div>
            </li>
          </ul>
        </nav>
        <div className={dropdown ? 'nav-spacer' : 'nav-spacer-hidden'}></div>
      </div>
    </>
  );
}

export default Navbar;
