import client from '../client';
import i18n from '../i18n';
import axios from 'axios';

const config = {
  headers:{
    timestamp: Date.now().toString()
  }
};

const getApi = async (path, params) => {
  let lang = i18n.language;
  let trial = 0;
  
  const runApi = async () => {
    try {
      const { data } = await client.get(path, { ...params, lang: lang }, config);
      return { success: true, data: data };
    } catch (err) {
      const { response } = err;
      const status = response && response.status ? response.status : err.message;
      const errormsg = response && response.data ? response.data.msg : err.message;
      return { success: false, data: null, errorstatus: status, errormsg: errormsg }
    }
  }
  
  let apiResult = await runApi();
  if( ! apiResult.success && trial < 3 ) {
    trial ++;
    apiResult = await runApi();
  } 
  return apiResult;
}

const postApi = async (path, params) => {
  let lang = i18n.language;
  let apiResult;
  try {
    apiResult = await client.post(path, { ...params, lang: lang });
    const { data } = apiResult;
    return { success: true, data: data };
  } catch (err) {
    const { response } = err;
    const status = response && response.status ? response.status : err.message;
    const errormsg = response && response.data ? response.data.msg : err.message;
    const errorcode = response && response.data ? response.data.code : err.message;
    return { success: false, data: errorcode, errorstatus: status, errormsg: errormsg }
  }
}

const getSubCategories = async (category) => {
  const apiResult = await getApi('wp/v2/categories/?parent=' + category, {});
  return apiResult;
}

const getPostsFromSubCategories = async (category) => {
  let categoriesParam = '';
  let categories = {};
  let subcategories = {};

  const subCategories = await getSubCategories(category);
  if (subCategories.success) {
    const { data } = subCategories;
    data.forEach((item) => {
      categoriesParam += item.id + ',';
      subcategories[item.id] = item.name;
    });
    const postData = await getPostsFromCategory(categoriesParam);
    if (postData.success && Array.isArray(postData.data)) {
      postData.data.forEach((post) => {
        const category = post.categories[0];
        const categoryName = subcategories[category];
        if (!categories.hasOwnProperty(categoryName)) {
          categories[categoryName] = [];
        }
        categories[categoryName] = [...categories[categoryName], post];
      });
    }
    return categories;
  }
  return {};

}

const getPostsFromCategory = async (category) => {
  const apiResult = await getApi('wp/v2/posts?per_page=100&categories=' + category, {});
  return apiResult;
}

const sendContactForm = async (name, country, email, message, checker) => {
  let formData = new FormData();
  formData.append('your-name', name);
  formData.append('your-country', country);
  formData.append('your-email', email);
  formData.append('your-message', message);
  formData.append('submission-type', 'Contact');
  formData.append('your-subject', 'Contact');
  formData.append('spam-checker', checker);
  formData.append('_wpcf7_unit_tag', 'form_id');

  try {
    const apiResult = await axios.post(
      process.env.REACT_APP_API_URL + "contact-form-7/v1/contact-forms/112/feedback",
      formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { data } = apiResult;
    if (data && data.status && data.status === 'mail_sent') {
      return { success: true, data: apiResult };
    }
    else {
      return { success: false, data: data.message };
    }
  } catch (err) {
    const { response } = err;
    const message = response && response.status ? response.status : err.message;
    const errormsg = response && response.data ? response.data.msg : err.message;
    return { success: false, data: message, errormsg: errormsg }
  }
}

const sendSubscribeForm = async (
  firstname, lastname, email, country, language, phone, event, comment, listid, topicid) => {
  const formDataToSend = {
    "email": email,
    "first_name": firstname,
    "last_name": lastname,
    "extra_fields": [
      { "id": 1, "value": country },
      { "id": 2, "value": language },
      { "id": 3, "value": comment },
      { "id": 4, "value": phone },
      { "id": 5, "value": event }
    ],
    "optin": "double"
  };
  formDataToSend['list_'+event] = 1;
  //const apiResult = await postApi('newsletter/v2/subscriptions', formDataToSend);
  const apiResult = await postApi('newsletter/askingcreatesubscription', formDataToSend);
  return apiResult;
}

const activateSubscriber = async (userEmail, userToken) => {
  const params = {
    'email': userEmail,
    'token': userToken
  }
  const apiResult = await postApi('newsletter/askingactivatesubscription', params);
  return apiResult;
}

const unsubscribeSubscriber = async (userToken, userEmail, listId) => {
  const params = {
    'email': userEmail,
    'token': userToken,
    'listid': listId
  }
  const apiResult = await postApi('newsletter/askingdeactivatesubscription', params);
  return apiResult;
}

const getPostById = async (postId) => {
  const apiResult = await getApi('wp/v2/posts?include[]=' + postId, {});
  return apiResult;
}

export {
  postApi, getPostsFromCategory, getPostsFromSubCategories,
  sendContactForm, sendSubscribeForm, getPostById, activateSubscriber, unsubscribeSubscriber
};