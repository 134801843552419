import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPostsFromCategory } from '../../clientwrapper';
import { formatText, getHtmlText } from '../../utils/htmhelper';
import { useTranslation } from 'react-i18next';

const EventsList = (props) => {

  const [events, setEvents] = useState(null);
  const [mounted, setMounted] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchEvents = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_GROUPEVENTS);
      const { data, success } = apiResult;
      if (success) {
        setEvents(data);
      }
    };

    if (mounted) {
      fetchEvents();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <>
      {events && Array.isArray(events) && events.length > 0 ? events.map((event, i) => (
        <div key={event.id} className='evenlist-item'>
          <div className="event-inner">
            {event.acf.banner && (
              <div className="banner-container"><Link to={`/event/${event.acf.details_post_id}`}><img src={event.acf.banner} alt="banner"></img></Link></div>
            )}
            <div className='event-inner-container'>
              <div className="event-left">
                {event.title && event.title.rendered && (
                  <Link to={`/event/${event.acf.details_post_id}`}><div className="event-title" dangerouslySetInnerHTML={getHtmlText(event.title.rendered)}></div></Link>
                )}
                {event.content && event.content.rendered && (
                  <div className="event-content" dangerouslySetInnerHTML={getHtmlText(event.content.rendered)}></div>
                )}
                {event.acf.details_post_id && (
                  <div className="readmore"><Link to={`/event/${event.acf.details_post_id}`}>{t('groupevents.readmore')}</Link></div>
                )}

              </div>
              <div className="event-right">
                {event.acf.tags && formatText(event.acf.tags).split('<br>').map((item, i) => (
                  <div className="tag-item" key={i}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )) : (
        <div className='no-event-container'>
          {t('groupevents.noevent.text')}
        </div>
      ) }
    </>
  );
};

export default EventsList;