import React, { useEffect, useState } from 'react';
import { getPostById } from '../clientwrapper';
import { getHtmlText } from '../utils/htmhelper';

const PostPage = (props) => {
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const postId = props.match.params.postid;

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostById(postId);
      const { data, success } = apiResult;
      if (success) {
        setTexts(data[0]);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted, postId]);

  return (
    <div className="post-container">
      <div className="post-title" dangerouslySetInnerHTML={texts && getHtmlText(texts.title.rendered)}></div>
      <div className="post-content" dangerouslySetInnerHTML={texts && getHtmlText(texts.content.rendered)} ></div>
    </div>
  );
};

export default PostPage;