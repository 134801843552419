export const checkInput = (inputData, validators) => {
  let err = false;
  validators.some((validator) => {
    err = validatorFunctions[validator](inputData);
    return err;
  });
  return err;
}

const validatorFunctions = {
  empty: (inputData) => {
    const { inputName, inputValue } = inputData;
    if (inputValue.trim() === '') { return 'empty_' + inputName; }
    return false;
  },
  email: (inputData) => {
    const { inputName, inputValue } = inputData;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(inputValue).toLowerCase())) {
      return 'invalid_' + inputName;
    }
    return false;
  },
  checkbox: (inputData) => {
    const { inputName, inputValue } = inputData;
    if (!inputValue) { return 'empty_' + inputName; }
    return false;
  },
  password: (inputData) => {
    const { inputValue } = inputData;
    const strongRegex = new RegExp("^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    const mediumRegex = new RegExp("^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
    const enoughRegex = new RegExp("(?=.{8,}).*", "g");
    if (inputValue.trim() === '') {
      return { status: 'error', strength: '', msg: 'empty_password' };
    }
    else if (false === enoughRegex.test(inputValue)) {
      return { status: 'error', strength: '', msg: 'short_password' };
    } else if (strongRegex.test(inputValue)) {
      return { status: 'ok', strength: 'strong', msg: 'strong_password' };
    } else if (mediumRegex.test(inputValue)) {
      return { status: 'ok', strength: 'medium', msg: 'medium_password' };
    } else {
      return { status: 'ok', strength: 'weak', msg: 'weak_password' };
    }
  },
  password2: (inputData) => {
    const { inputName, inputValue, referenceValue } = inputData;
    if (inputValue !== referenceValue) { return 'invalid_' + inputName; }
    return false;
  }
}
