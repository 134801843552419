import React from 'react';
import { getText } from '../../utils/htmhelper';
import indIcon from '../../images/group/icon_individual.png';
import groupIcon from '../../images/group/icon_group.png';
import teamIcon from '../../images/group/icon_team.png';
import colSeparator from '../../images/group/column_separator.png';

const TheProgram = (props) => {
  const { texts } = props;

  return (
    <div className="group-program-container">
      <div className="gr-title">
        {texts && texts.acf.title}
      </div>
      <div className="gr-text1"
        dangerouslySetInnerHTML={texts && getText(texts.acf.explanation)} >
      </div>
      <div className="columns-container">
        <div className="col">
          <div className="col-title">
            <div className="col-title-text">
              {texts && texts.acf.ind_title}
            </div>
            <div className="col-title-image">
              <img src={indIcon} alt=""></img>
            </div>
          </div>
          <div className="col-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.ind_explanation)} >
          </div>
        </div>
        <div className="col-separator">
          <img src={colSeparator} alt=""></img>
        </div>
        <div className="col">
          <div className="col-title">
            <div className="col-title-text">
              {texts && texts.acf.group_title}
            </div>
            <div className="col-title-image">
              <img src={groupIcon} alt=""></img>
            </div>
          </div>
          <div className="col-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.group_explanation)} >
          </div>
        </div>
        <div className="col-separator">
          <img src={colSeparator} alt=""></img>
        </div>
        <div className="col">
          <div className="col-title">
            <div className="col-title-text">
              {texts && texts.acf.team_title}
            </div>
            <div className="col-title-image">
              <img src={teamIcon} alt=""></img>
            </div>
          </div>
          <div className="col-text"
            dangerouslySetInnerHTML={texts && getText(texts.acf.team_explanation)} >
          </div>
        </div>

      </div>
    </div>
  );
};

export default TheProgram;