export const formatText = (str) => {
  return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

export const getText = (str) => {
  str = formatText(str);
  return { __html: str };
}

export const getHtmlText = (str) => {
  return { __html: str };
}