import React, { useEffect, useState } from 'react';
import { getPostById } from '../clientwrapper';
import { getHtmlText, getText } from '../utils/htmhelper';
import leftArrow from '../images/events/double_left.png';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

const EventPage = (props) => {
  const animParams = getAnimationParams('page');
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const postId = props.match.params.postid;

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostById(postId);
      const { data, success } = apiResult;
      if (success) {
        setTexts(data[0]);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted, postId]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      { texts ? 
      <div className="post-container">
        {texts && texts.acf.banner && (
          <div className="banner-container"><img src={texts.acf.banner} alt="banner"></img></div>
        ) }
        {texts && texts.acf.link && (
          <div className="link-container"><img src={leftArrow} alt=""></img><div><Link to={texts.acf.link}>{texts.acf.link_text}</Link></div></div>
        ) }
        <div className="post-title" dangerouslySetInnerHTML={texts && getHtmlText(texts.title.rendered)}></div>
        <div className="post-content">
          <p className="yellow-text aligncenter" dangerouslySetInnerHTML={texts && getText(texts.acf.introduction)}></p>
          <p className="register-button-container">
            <a href={`/registergroup/${postId}`}><button className="button-general">{texts && texts.acf.register_button}</button></a>
          </p>
          <p className="description-container" dangerouslySetInnerHTML={texts && getText(texts.acf.description)}></p>

          <div className="tagbox-container">
            <div className="tag box" dangerouslySetInnerHTML={texts && getText(texts.acf.box1)}></div>
            <div className="tag box box-right" dangerouslySetInnerHTML={texts && getText(texts.acf.box2)}></div>
          </div>

          <p className="timezone" dangerouslySetInnerHTML={texts && getText(texts.acf.timezone)}></p>
          <p className="closing-text" dangerouslySetInnerHTML={texts && getText(texts.acf.closing_text)}></p>
          <p className="register-button-container">
            <a href={`/registergroup/${postId}`}><button className="button-general">{texts && texts.acf.register_button}</button></a>
          </p>
        </div>
      </div>
      : null }
    </motion.div>
  );
};

export default EventPage;