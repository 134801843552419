import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <div className="nopage-container">
      <div className="nopage-content">
        <div className="nopage-title">
          404
        </div>
        <div className="nopage-title">{t('nopage.title')}</div>
        <div className="nopage-content">{t('nopage.text')}</div>
        <div className="nopage-button">
          <Link to="/">{t('nopage.button')}</Link>
        </div>
      </div>
    </div>
  );
};

export default Page404;