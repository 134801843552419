import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


export default function MessageBox(props) {
  const { t } = useTranslation();
  const node = useRef();
  const resetMsg = (e) => {
    e.preventDefault();
    props.resetMsg();
  }

  useEffect(() => {
    // add when mounted
    const handleClick = e => {
      if (node.current.contains(e.target)) {
        return;
      }  // outside click 
      props.resetMsg();
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [props]);

  return (
    <div className="message-box-container" ref={node}>
      <div className={`message-icon-${props.variant}`}>
        {props.variant === "danger"
          ? <FontAwesomeIcon icon={faExclamationCircle} />
          : <FontAwesomeIcon icon={faCheckCircle} />}
      </div>
      <div className={`alert alert-${props.variant || 'info'}`}>
        {props.children}
      </div>
      <div className="message-box-buttons">
        <button type="button" onClick={(e) => resetMsg(e)}>{t('general.messages.ok')}</button>
      </div>
    </div>
  );
}
