import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { getPostsFromCategory } from '../../clientwrapper';
import { getHtmlText } from '../../utils/htmhelper';
import { motion, AnimatePresence } from 'framer-motion';
import { getAnimationParams } from '../../utils/animationhelper';
import bgImg from '../../images/landing/landing_bg.png';

const LandingInfo = (props) => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([]);
  const cookieName = "theasking_show_landingpage";
  const [visible, setVisible] = useState(true);
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const animParams = getAnimationParams('landing');
  
  const setLandingCookie = () => {
    setVisible(false);
  }

  useEffect(() => {
    var today = new Date();
    var expire = new Date();
    expire.setTime(today.getTime() + 3600000*24*7);

    const cookieOptions = {
      path: '/',
      expires: expire
    }
  
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_LANDING);
      if( apiResult && apiResult.success && apiResult.data && apiResult.data.length > 0 ) {
        const filteredText = apiResult.data[0];
        setTexts(filteredText);
      }   
    };

    if (mounted) {
      fetchData();
    }

    if (cookies[cookieName] && cookies[cookieName] === "1") {
      setVisible(false);
    } else if( visible ) {
      setCookie(cookieName, "1", cookieOptions);
      setVisible(true);
    }

    return () => { setMounted(false) }

  }, [cookies, props, mounted, visible, setCookie]);

  return (
    <AnimatePresence exitBeforeEnter>
    {(texts && visible && 
      <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="landing-container"
      >
      <div className="landing-container-modal-close" onClick={setLandingCookie}>X</div>
      
      <div className="landing-inner">
        
        <div className="landing-inner-top" style={{ backgroundImage: `url(${bgImg})` }}>
          
          <div className="landing-text-container" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.top)}>
          </div>

        </div>
        <div className="landing-inner-bottom">
          <div className="landing-text-container-bottom" dangerouslySetInnerHTML={texts && getHtmlText(texts.acf.bottom  )}>
          </div>
        </div>
      </div>
    </motion.div>
    )}
    </AnimatePresence>
  );
};

export default LandingInfo;