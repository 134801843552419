import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import circle1 from '../../images/group/circle1.png';
import circle2 from '../../images/group/circle2.png';
import { formatDate } from '../../utils/datehelper';
import HeaderBg from '../../images/group/header_bg.png';
import { Link } from 'react-router-dom';

const UpcomingEvents = (props) => {

  const { events, nextEvent, texts } = props;
  const { t } = useTranslation();

  const programRef = useRef(null);

  const selectEvent = (eventId) => {
    let selectedEvent = events.find(o => o.id === eventId);
    props.setNextEvent(selectedEvent);
    programRef.current.scrollIntoView(true);
  };

  return (
    <>
      {events && Array.isArray(events) && events.length > 0 ? (
      
      <>
      <div className="next-event-container" >
        <div className='scrollto8' ref={programRef} ></div>
        <div className="next-event-inner">
          <div className="next-event-left">
            <div className="next-event-title">
              <Link to='/events'>
                {t('groupevents.upcoming_events_link')}
              </Link>
            </div>

            <div className="data-container">
              <div className="ns-register-by">
                <div className="fix">{texts && texts.acf.registerby}</div>
                <div className="dynamic">{nextEvent && formatDate(nextEvent.acf.register_by)}</div>
              </div>

              <div className="ns-next-session">
                <div className="fix">{texts && texts.acf.sessionbegins}</div>
                <div className="dynamic">{nextEvent && formatDate(nextEvent.acf.begin)}</div>
              </div>

              <div className="ns-introduction ns-param1">
                <div className="ns-param-name">
                  <div className="image-container">
                    <img src={circle1} alt=""></img>
                  </div>
                  <div className="fix">{texts && texts.acf.introduction}</div>
                </div>
                <div className="dynamic">{nextEvent && formatDate(nextEvent.acf.begin)}</div>
              </div>

              <div className="ns-interchange ns-param1">
                <div className="ns-param-name">
                  <div className="image-container">
                    <img src={circle2} alt=""></img>
                  </div>
                  <div className="fix">{texts && texts.acf.interchange}</div>
                </div>
                <div className="dynamic">{nextEvent && formatDate(nextEvent.acf.interchange)}</div>
              </div>

              <div className="ns-summary ns-param1">
                <div className="ns-param-name">
                  <div className="image-container">
                    <img src={circle1} alt=""></img>
                  </div>
                  <div className="fix">{texts && texts.acf.summary}</div>
                </div>
                <div className="dynamic">{nextEvent && formatDate(nextEvent.acf.summary)}</div>
              </div>
            </div>

            <div className="ns-footer">
            {texts && texts.acf.starttime}
            </div>
          </div>
          <div className="next-event-right">
            <Link to={{
              pathname: `/registergroup`,
              event: { nextEvent }
            }}>
              <div className="ns-register">
              {texts && texts.acf.dothis}
              </div>
            </Link>
            <div className="ns-register-title">
              {texts && texts.acf.register_title}
            </div>
            <div className="ns-register-text">
            {texts && texts.acf.register_text}
            </div>
          </div>
        </div>
      </div>
      <div className="groupevents-container">
        <div className="groupevents-container-bg" style={{ backgroundImage: `url(${HeaderBg})` }}>
          <div className="ge-box">
            <div className="ge-title">
              {t('groupevents.title')}
            </div>
            <div className="ge-container">
              <div className="ge-cont-title">
                {t('groupevents.next_session')}
              </div>
              <div className="ge-list">
                {events && Array.isArray(events) && events.map((event, i) => (
                  <div key={event.id} className='ge-item' onClick={() => selectEvent(event.id)}>
                    {formatDate(event.acf.begin)}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="ge-footer">
            <div className="ge-footer-inner">
              {t('groupevents.footer')}
            </div>
          </div>
        </div>
      </div>
      </>
      ) : null }
    </>
  );
};

export default UpcomingEvents;