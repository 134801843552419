import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const EventSelector = (props) => {
  const [value, setValue] = useState('');
  const { eventList } = props;
  const { t } = useTranslation();

  const changeHandler = value => {
    setValue(value);
    props.setValue(value);
  }

  const customStylesAll = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0px'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '4.8rem',
      fontSize: '1.4rem'
    }),
    menuList: (provided, state) => ({
      ...provided,
      height: '10rem',
      fontSize: '1.4rem'
    }),
  }

  useEffect(
    () => {
      if (props.value) {
        setValue(props.value);
      }
    }, [props, value]
  );

  return (
    <Select options={eventList} className="event-selector-container"
      value={value} onChange={changeHandler} placeholder={t('contact.selectevent')}
      styles={customStylesAll} />
  );
};

export default EventSelector;