import React from 'react';
import { formatText, getText } from '../../utils/htmhelper';
import Circle from '../../images/group/circle.png';
import groupPhoto from '../../images/group/whatyouget.png';
import listSeparator from '../../images/group/list_separator.png';
import testimonyBg from '../../images/group/testimony-bg.png';
import footerBg from '../../images/group/wyg-footer-bg.png';
import { Link } from 'react-router-dom';

const WhatYouGet = (props) => {
  const { texts } = props;
  const { nextEvent } = props;

  const listItems = texts && texts.acf.list
    ? formatText(texts.acf.list).split('<br>') : [];

  return (
    <div className="wyg-container">
      <div className="circle">
        <img src={Circle} alt=""></img>
      </div>
      <div className="top">
        <div className="left"></div>
        <div className="right">
          <div className="title">
            {texts && texts.acf.title}
          </div>
        </div>
      </div>
      <div className="top">
        <div className="left">
          <img src={groupPhoto} alt=""></img>
        </div>
        <div className="right">
          <div className="list">
            {listItems.map((item, i) => (
              <div className="list-item" key={i}>
                <div><img src={listSeparator} alt=""></img></div>
                <div>{item}</div>
              </div>
            ))}
          </div>

        </div>
      </div>
      <div className="group-testimony-container">
        <div className="group-testimony-container-inner">
          <div className="group-testimony-container1" style={{ backgroundImage: `url(${testimonyBg})` }}>
            <div className="testimony1-inner">
              <div className="testimony"
                dangerouslySetInnerHTML={texts && getText(texts.acf.testimony)}>
              </div>
            </div>
          </div>
          <Link to={{
            pathname: `/registergroup`,
            event: { nextEvent }
          }}>
            <span className="link">
              <div className="doit">
                {texts && texts.acf.dothis}
              </div>
            </span>
          </Link>
        </div>
      </div >
      <div className="group-wyg-footer-container" style={{ backgroundImage: `url(${footerBg})` }}>
        <div className="footer-inner"
          dangerouslySetInnerHTML={texts && getText(texts.acf.footer)} >
        </div>

      </div>
    </div>
  );
};

export default WhatYouGet;