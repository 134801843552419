import React from 'react';
import { getText } from '../../utils/htmhelper';

const OurTeam = (props) => {
  const { texts } = props;

  return (
    <div className="team-container">
      <div className="team-title">
        {texts && texts.acf.title}
      </div>
      <div className="team-text"
        dangerouslySetInnerHTML={texts && getText(texts.acf.text)}>
      </div>
    </div>
  );
};

export default OurTeam;