import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../../clientwrapper';
import { formatText, getHtmlText, getText } from '../../utils/htmhelper';
import arrowRightList from '../../images/whatsnext/list_arrow_right.png';
import silosMessage from '../../images/whatsnext/silos_message.png';
import arrowRight from '../../images/whatsnext/arrow-right.png';
import arrowDown from '../../images/whatsnext/list_arrow_down.png';
import seminarDecorator from '../../images/whatsnext/seminar_decorator.png';
import { motion, AnimatePresence } from 'framer-motion';
import separator from '../../images/whatsnext/separator.png';
import { useDispatch } from 'react-redux';
import { toggleIndividualRegistrationForm } from '../../redux/actions/appDataActions';

const Participate = (props) => {

  const dispatch = useDispatch();
  const { texts } = props;
  const [communities, setCommunities] = useState(null);
  const [mounted, setMounted] = useState(true);
  const [opened, setOpened] = useState(false);
  const seminarTopicsList = texts && texts.acf.seminar_topics_list
    ? formatText(texts.acf.seminar_topics_list).split('<br>') : [];

  const variants = {
    visible: { opacity: 1, height: 'auto', transition: { duration: 0.5 } },
    hidden: {
      opacity: 0, height: 0, transition: { duration: 0.5 }
    }
  };

  const footerItems = texts ? texts.acf.seminar_footer.split('<reg>') : null;

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_COMMUNITIES);
      const { data, success } = apiResult;
      if (success) {
        setCommunities(data);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <div className="participate-container">
      <div className="participate-inner">
        <div className="participate-title-container">
          <div className="participate-title-decorator">
            <img src={arrowRight} alt=""></img>
          </div>
          <div className="participate-title" dangerouslySetInnerHTML={texts && getText(texts.acf.community_title)}>
          </div>
        </div>
        <div className="community-intro-container">
          <div className="community-intro" dangerouslySetInnerHTML={texts && getText(texts.acf.community_intro)}></div>
          <div className="community-intro-img-container">
            <a href="https://www.amazon.com/Silos-Message-Silo/dp/1878977415" target="_blank" rel="noreferrer"><img src={silosMessage} alt="silos message"></img></a>
          </div>
        </div>
        <div className="community-contact" dangerouslySetInnerHTML={texts && getText(texts.acf.community_contact)}></div>
        <div className="community-contact-intro" dangerouslySetInnerHTML={texts && getText(texts.acf.community_contact_intro)}></div>
        <div className="community-list-container">
          <div className="list-opener">
            <div className="list-img-container" onClick={() => setOpened(!opened)}>
              <img src={opened ? arrowDown : arrowRightList} alt={opened ? '>' : '>>'}></img>
            </div>
            <div className="list-right">
              <div className="list-title" onClick={() => setOpened(!opened)}>
                {texts && texts.acf.community_list_title}
              </div>
              <div className="list-items">
                <AnimatePresence>
                  <motion.div
                    variants={variants}
                    animate={opened ? 'visible' : 'hidden'}>
                    {communities && Array.isArray(communities) && communities.map((c, i) => (
                      <div key={i} className="community-list-item" dangerouslySetInnerHTML={getHtmlText(c.content.rendered)}>
                      </div>
                    ))}
                  </motion.div>
                </AnimatePresence>
              </div>
              <div className="separator">
                <img src={separator} alt=""></img>
              </div>
            </div>
          </div>
        </div>
        <div className="seminar-container">
          <div className="participate-title-container">
            <div className="participate-title-decorator">
              <img src={arrowRight} alt=""></img>
            </div>
            <div className="participate-title" dangerouslySetInnerHTML={texts && getText(texts.acf.seminar_title)}>
            </div>
          </div>
          <div className="community-intro-container">
            <div className="community-intro" dangerouslySetInnerHTML={texts && getText(texts.acf.seminar_text)}></div>
            <div className="community-intro-img-container">
              <img src={seminarDecorator} alt="silos message"></img>
            </div>
          </div>
          <div className="community-contact" dangerouslySetInnerHTML={texts && getText(texts.acf.seminar_topics_title)}></div>
          <div className="community-contact-intro">
            <ul className="seminar-list">
              {seminarTopicsList.map((item, i) => (
                <li className="seminar-list-item" key={i}>
                  <div>{item}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="seminar-footer">
            <span dangerouslySetInnerHTML={footerItems && getText(footerItems[0])}></span>
            <span dangerouslySetInnerHTML={footerItems && getText('<b>' + footerItems[1] + '</b>')}
              onClick={() => { dispatch(toggleIndividualRegistrationForm()) }} className="link"></span>
            <span dangerouslySetInnerHTML={footerItems && getText(footerItems[2])}></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Participate;