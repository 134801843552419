import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import DayZero from '../components/days/DayZero';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

const DayZeroPage = (props) => {

  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_DAYZERO);
      const { data, success } = apiResult;
      if (success) {
        let filteredText = [];
        data.forEach((datatext) => {
          filteredText[datatext.title.rendered] = datatext;
        })
        setTexts(filteredText);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <DayZero texts={texts && texts['Day0']}></DayZero>
    </motion.div>
  );
};

export default DayZeroPage;