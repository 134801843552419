import React from 'react';
import { getText } from '../../utils/htmhelper';
import testimonyBg from '../../images/individual/testimony-bg.png';

const Testimony = (props) => {
  const { texts } = props;

  return (
    <div className="ind-testimony-container">
      <div className="ind-testimony-container-inner">
        <div className="testimony1-container" style={{ backgroundImage: `url(${testimonyBg})` }}>
          <div className="testimony1-inner">
            <div className="testimony1" dangerouslySetInnerHTML={texts && getText(texts.acf.testimony1)}></div>
          </div>
        </div>
        <div className="testimony2-container" style={{ backgroundImage: `url(${testimonyBg})` }}>
          <div className="testimony2-inner">
            <div className="testimony2" dangerouslySetInnerHTML={texts && getText(texts.acf.testimony2)}></div>
          </div>
        </div>
      </div>
      <div className="question" dangerouslySetInnerHTML={texts && getText(texts.acf.faq)}></div>
    </div >
  );
};
export default Testimony;