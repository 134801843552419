import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Footer from './components/general/Footer';
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';
import SiloPage from './pages/SiloPage';
import { useRef } from 'react';
import IndividualProgram from './pages/IndividualProgram';
import GroupProgram from './pages/GroupProgram';
import TeamPage from './pages/TeamPage';
import Days from './pages/Days';
import DayZeroPage from './pages/DayZeroPage';
import ContactPage from './pages/ContactPage';
import { AnimatePresence } from 'framer-motion';
import Navbar from './components/navigation/Navbar';
import FAQPage from './pages/FAQPage';
import RegisterPageGroup from './pages/RegisterPageGroup';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ActivateRegistration from './pages/ActivateRegistration';
import CreditPage from './pages/CreditPage';
import ConclusionsPage from './pages/ConclusionsPage';
import WhatsNextPage from './pages/WhatsNextPage';
import Cookie from './pages/Cookie';
import UnsubscribePage from './pages/UnsubscribePage';
import withTracker from './components/utils/withTracker';
import RegisterFormIndividual from './components/register/RegisterFormIndividual';
import Resources from './pages/Resources';
import PostPage from './pages/PostPage';
import EventsPage from './pages/EventsPage';
import EventPage from './pages/EventPage';

function Content(props) {
  const location = useLocation();
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const testimonyRef = useRef(null);
  const howitworksRef = useRef(null);
  const benefitsRef = useRef(null);
  const { setShowCookieAlert } = props;

  const refs = {
    "videoRef": videoRef, "audioRef": audioRef, "testimonyRef": testimonyRef, "howitworksRef": howitworksRef,
    "benefitsRef": benefitsRef
  }

  const WithTrackerHomePage = withTracker(HomePage);
  const WithTrackerIndividualProgram = withTracker(IndividualProgram);
  const WithTrackerGroupProgram = withTracker(GroupProgram);
  const WithTrackerSiloPage = withTracker(SiloPage);
  const WithTrackerTeamPage = withTracker(TeamPage);
  const WithTrackerDayZeroPage = withTracker(DayZeroPage);
  const WithTrackerDays = withTracker(Days);
  const WithTrackerContactPage = withTracker(ContactPage);
  const WithTrackerRegisterPageGroup = withTracker(RegisterPageGroup);
  const WithTrackerFAQPage = withTracker(FAQPage);
  const WithTrackerPrivacyPolicy = withTracker(PrivacyPolicy);
  const WithTrackerResources = withTracker(Resources);
  const WithTrackerCookie = withTracker(Cookie);
  const WithTrackerActivateRegistration = withTracker(ActivateRegistration);
  const WithTrackerUnsubscribePage = withTracker(UnsubscribePage);
  const WithTrackerCreditPage = withTracker(CreditPage);
  const WithTrackerConclusionsPage = withTracker(ConclusionsPage);
  const WithTrackerWhatsNextPage = withTracker(WhatsNextPage);
  const WithTrackerPostPage = withTracker(PostPage);
  const WithTrackerEventsPage = withTracker(EventsPage);
  const WithTrackerEventPage = withTracker(EventPage);

  return (
    <>
      <div className="main-container">
        <div className="main">
          <div className="content-container">
            <RegisterFormIndividual></RegisterFormIndividual>
            <Navbar scrollToContent={props.scrollToContent}
              refs={props.refs}></Navbar>
            <AnimatePresence exitBeforeEnter initial={false}>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/"
                  render={(props) => <WithTrackerHomePage refs={refs} {...props} />} />

                <Route exact path="/individual"
                  render={(props) => <WithTrackerIndividualProgram refs={refs} {...props} />} />

                <Route exact path="/group"
                  render={(props) => <WithTrackerGroupProgram refs={refs} {...props} />} />

                <Route exact path="/silothegift"
                  render={(props) => <WithTrackerSiloPage refs={refs} {...props} />} />

                <Route exact path="/ourteam"
                  render={(props) => <WithTrackerTeamPage refs={refs} {...props} />} />

                <Route exact path="/day/0"
                  render={(props) => <WithTrackerDayZeroPage refs={refs} {...props} />} />

                <Route path="/day/:day"
                  render={(props) => <WithTrackerDays refs={refs} {...props} />} />

                <Route exact path="/contact"
                  render={(props) => <WithTrackerContactPage refs={refs} {...props} />} />

                <Route exact path="/registergroup"
                  render={(props) => <WithTrackerRegisterPageGroup refs={refs} type="default" {...props} />} />

                <Route path="/registergroup/:eventPostId"
                  render={(props) => <WithTrackerRegisterPageGroup refs={refs} type="specific" {...props} />} />

                <Route exact path="/faq"
                  render={(props) => <WithTrackerFAQPage refs={refs} {...props} />} />

                <Route exact path="/privacypolicy"
                  render={(props) => <WithTrackerPrivacyPolicy refs={refs} {...props} />} />

                <Route exact path="/resources"
                  render={(props) => <WithTrackerResources refs={refs} {...props} />} />

                <Route exact path="/cookies"
                  render={(props) => <WithTrackerCookie refs={refs} setShowCookieAlert={setShowCookieAlert} {...props} />} />

                <Route exact path="/activateregistration"
                  render={(props) => <WithTrackerActivateRegistration refs={refs} {...props} />} />

                <Route exact path="/unsubscribe"
                  render={(props) => <WithTrackerUnsubscribePage refs={refs} {...props} />} />

                <Route exact path="/credit"
                  render={(props) => <WithTrackerCreditPage refs={refs} {...props} />} />

                <Route exact path="/conclusions"
                  render={(props) => <WithTrackerConclusionsPage refs={refs} {...props} />} />

                <Route exact path="/whatsnext"
                  render={(props) => <WithTrackerWhatsNextPage refs={refs} {...props} />} />
                
                <Route exact path="/events"
                  render={(props) => <WithTrackerEventsPage refs={refs} {...props} />} />

                <Route path="/post/:postid"
                  render={(props) => <WithTrackerPostPage refs={refs} {...props} />} />

                <Route path="/event/:postid"
                  render={(props) => <WithTrackerEventPage refs={refs} {...props} />} />

                <Route component={Page404} />
              </Switch>
            </AnimatePresence>
            <Footer></Footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;