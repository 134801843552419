import React from 'react';
import headerImage from '../../images/header/background.jpg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getText } from '../../utils/htmhelper';

function Header(props) {
  const { t } = useTranslation();
  const { texts } = props;

  return (
    <>
      <div className="header" style={{ backgroundImage: `url(${headerImage})` }} >
        <div className="header-inner">
          <div className="header-container">
            <div className="header-title">{texts && texts.acf.title}</div>
            <div className="header-subtitle">{texts && texts.acf.text_1}</div>
            <div className="header-text" dangerouslySetInnerHTML={texts && getText(texts.acf.text_2)}></div>
          </div>
        </div>
        <div className="header-buttons">
          <Link to="/individual"><div className="header-button">{t('program.individual')}</div></Link>
          <Link to="/group"><div className="header-button">{t('program.group')}</div></Link>
        </div>
      </div>
    </>
  );
}

export default Header;