import React, { useEffect, useState } from 'react';
import clickOpen from '../../images/openprogram/click_open.png';
import clickClose from '../../images/openprogram/click_close.png';
import { motion, AnimatePresence } from 'framer-motion';
import Program from './Program';
import { useDispatch, useSelector } from 'react-redux';
import { toggleIndividualRegistrationForm } from '../../redux/actions/appDataActions';
import { useTranslation } from "react-i18next";

const OpenProgram = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData);
  const { showIndRegForm } = appData;

  const [showProgram, setShowProgram] = useState(false);
  const [rotate, setRotate] = useState(false);

  useEffect(() => {
    setRotate(showProgram);
  }, [showProgram]);


  const variants = {
    rotate: { rotate: [0, -30, 0], transition: { duration: 0.5 } },
    stop: { rotate: [0, 30, 0], transition: { duration: 0.5 } }
  };

  const compSetShowProgram = () => {
    toggleProgram();
    setRotate(!rotate);
  };

  const toggleProgram = () => {
    if (!showProgram) {
      if (showIndRegForm) {
        dispatch(toggleIndividualRegistrationForm());
      }
      //document.body.style.overflow = 'hidden';
      //document.body.style.marginRight = '20px';
      setShowProgram(true);
    } else {
      //document.body.style.overflow = 'unset';
      //document.body.style.marginRight = '0px';
      setShowProgram(false);
    }
  };

  return (
    <>
      <div className="open-program" onClick={compSetShowProgram}>
        <div className={props.showProgram ? 'fade-in-image-open' : 'fade-in-image-close'}>
          <AnimatePresence exitBeforeEnter>
            <motion.img
              variants={variants}
              animate={rotate ? 'rotate' : 'stop'}
              src={showProgram ? clickOpen : clickClose} alt="program"></motion.img>
          </AnimatePresence>
          <div className="gotodays">{t('navigation.gotodays')}</div>
        </div>
      </div>
      <Program toggleProgram={toggleProgram} showProgram={showProgram}></Program>
    </>
  );
};

export default OpenProgram;