import React from 'react';
import footerLogo from '../../images/footer/footer_logo.png';
import { useTranslation } from 'react-i18next';
import instaLogo from '../../images/footer/instagramlogo.png';
import facebookLogo from '../../images/footer/facebooklogo.png';
import { Link } from 'react-router-dom';
import { FaSortNumericUpAlt } from 'react-icons/fa';

function Footer(props) {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer-main">
        <div className="logo-container">
          <img src={footerLogo} alt=""></img>
          <div>{t('navigation.theasking')}</div>
        </div>
        <div className="footer-container">
          <div className="footer-top">
            <div className="footer-1">
              <Link to="/conclusions"><div>{t('individual.conclusions')}</div></Link>
            </div>
            <div className="footer-2">
              <Link to="/resources"><div>{t('navigation.resources')}</div></Link>
            </div>
            <div className="footer-3">
              <Link to="/credit"><div>{t('navigation.credit')}</div></Link>
            </div>
            <div className="footer-4">
              <Link to="/contact"><div>{t('navigation.contact')}</div></Link>
            </div>
            <div className="footer-6">
              <Link to="/privacypolicy"><div>{t('navigation.privacypolicy')}</div></Link>
            </div>
            <div className="footer-5">
              <div>{t('navigation.followus')}</div>
              <div className="social">
                { t('social.instagram') !== '' && 
                  <a href={t('social.instagram')} rel="noreferrer" target="_blank">
                    <img src={instaLogo} alt="insta"></img>
                  </a>
                }
                { t('social.facebook') !== '' && 
                  <a href={t('social.facebook')} rel="noreferrer" target="_blank">
                    <img src={facebookLogo} alt="fb"></img>
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Footer;