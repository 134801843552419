import React, { useCallback, useEffect, useState, useRef } from 'react';
import { checkInput } from '../../utils/validators';
import { useTranslation } from 'react-i18next';
import CountrySelector from '../utils/CountrySelector';
import { getHtmlText, getText } from '../../utils/htmhelper';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendRegistrationMessage, resetSubscribe } from '../../redux/actions/userActions';
import { toggleIndividualRegistrationForm } from '../../redux/actions/appDataActions';
import MessageBox from '../utils/MessageBox';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getPostsFromCategory } from '../../clientwrapper';
import { motion, AnimatePresence } from 'framer-motion';
import { getAnimationParams } from '../../utils/animationhelper';

const RegisterFormIndividual = (props) => {
  const { t } = useTranslation();
  const [texts, setTexts] = useState(null);
  const [mounted, setMounted] = useState(true);
  const dispatch = useDispatch();
  const userSubscribe = useSelector((state) => state.userSubscribe);
  const appData = useSelector((state) => state.appData);

  const animParams = getAnimationParams('signup');

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');

  const { status, loading, msg } = userSubscribe;
  const userType = msg && msg.user_status ? msg.user_status : null;

  const { showIndRegForm } = appData;

  const Refs = {
    name: useRef(null),
    lastname: useRef(null),
    email: useRef(null),
    toc: useRef(null)
  };

  const initialState = {
    name: '', lastname: '', email: '', country: { value: '', label: '' }, toc: '', comment: '', emailfrom: ''
  };

  const initialStateError = {
    name: '', lastname: '', email: '', country: '', toc: '', comment: '', emailfrom: ''
  };

  const [formData, setFormData] = useState(initialState);
  const [formDataError, setFormDataError] = useState(initialStateError);
  const [showMsg, setShowMsg] = useState(false);

  const fieldsValidators = [
    { name: 'name', value: formData.name, validators: ['empty'] },
    { name: 'lastname', value: formData.lastname, validators: ['empty'] },
    { name: 'email', value: formData.email, validators: ['empty', 'email'] },
    { name: 'country', value: formData.country.value, validators: [] },
    { name: 'toc', value: formData.toc, validators: ['checkbox'] },
    { name: 'comment', value: formData.comment, validators: [] },
    { name: 'emailfrom', value: formData.emailfrom, validators: [] }
  ];

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked ? '1' : 0 : target.value;
    const name = target.name;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    const field = fieldsValidators.find(element => element.name === name);
    const referenceValue = '';
    const errorMsg = checkInput(
      { inputName: name, inputValue: value, referenceValue: referenceValue }, field.validators);
    setFormDataError(prevState => ({ ...prevState, [name]: errorMsg }));
  };

  const validateInput = () => {
    const hasError = fieldsValidators.some(field => {
      const referenceValue = null;
      const errorMsg = checkInput(
        { inputName: field.name, inputValue: field.value, referenceValue: referenceValue },
        field.validators);
      if (errorMsg) {
        setFormDataError(prevState => ({ ...prevState, [field.name]: errorMsg }));
        Refs[field.name].current.scrollIntoView(true);
        return true;
      }
      setFormDataError(prevState => ({ ...prevState, [field.name]: '' }));
      return false;
    });

    return hasError;
  };

  const setCountry = country => {
    setFormData(prevState => ({ ...prevState, country }));
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (token === null || token === '') {
      return;
    }
    if (formData.emailfrom !== '') {
      return;
    }
    // filter comment with url
    const urlRE = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
    if (formData.comment !== '' && formData.comment.match(urlRE)) {
      return;
    }
    submitForm();
  }

  const submitForm = () => {
    setFormDataError(initialStateError);
    const isInvalid = validateInput();
    if (!isInvalid) {
      dispatch(sendRegistrationMessage(
        formData.name, formData.lastname, formData.email, formData.country.label, '', '',
        formData.comment, 'individual', process.env.REACT_APP_ZOHO_DAILY_REMINDER_LISTID
      ));
    }
  };

  const resetMsg = () => {
    dispatch(resetSubscribe());
    setShowMsg('');
    if (status !== 'error') {
      dispatch(toggleIndividualRegistrationForm());
    }
    // reset form values
    //setFormData(initialState);
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0px'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '4.8rem',
      fontSize: '1.4rem'
    }),
    menuList: (provided, state) => ({
      ...provided,
      height: '10rem',
      fontSize: '1.4rem'
    }),
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha('signup');
    setToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {

    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_INDIVIDUALREGISTRATION);
      const { data } = apiResult;
      let filteredText = data[0];
      setTexts(filteredText);
    };

    if (mounted && !texts) {
      fetchData();
    }

    if (token === '') {
      handleReCaptchaVerify();
    }
    if (status) {
      setShowMsg(status);
    }

    return () => {
      setMounted(false);
      document.body.style.overflow = 'unset';
    }
  }, [status, handleReCaptchaVerify, token, mounted, showIndRegForm, texts]);

  return (
    <>
      {showMsg ?
        <MessageBox
          variant={status === 'error' ? 'danger' : 'info'}
          resetMsg={resetMsg}>
          <>
            {status === 'error' ? 
              t('contact.messages.' + msg) 
              : 
              <div dangerouslySetInnerHTML={getHtmlText(t('contact.messages.register_success'))}></div> 
            }
            {status !== 'error' && process.env.REACT_APP_SHOW_LOGIN_INFO && (
              <div className="login-info-container">
                <div>{t('contact.messages.register_success_login_text')}</div>
                <div>{t('contact.messages.register_success_login_info_user')}</div>
                <div>{t('contact.messages.register_success_login_info_password')}</div>
              </div>
            )}
          </>
        </MessageBox>
        : null
      }

      <AnimatePresence exitBeforeEnter>
        {showIndRegForm && (
          <motion.div
            transition={animParams['transition']}
            initial={animParams['initial']}
            animate={animParams['animate']}
            exit={animParams['animExit']}
            className="signup-container"
          >
            <div className="signup-container-close" onClick={() => { dispatch(toggleIndividualRegistrationForm()) }}>X</div>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA3_SITE_KEY}>
              <div className="register-container">
                <div className="register-left">
                  <div className="register-header">
                    <div className="register-title">
                      {texts && texts.acf.title}
                    </div>
                    <div className="register-text"
                      dangerouslySetInnerHTML={texts && getText(texts.acf.text)}></div>
                  </div>
                  <div className="register-form-container">

                    <form noValidate onSubmit={(e) => onSubmit(e)}>
                      
                      <div className="register-row register-row-ind">

                        <div className="input-container input-lastname">
                          <div ref={Refs.name} className="scrollto"></div>
                          <div className="input-label">
                            {t('contact.name')}
                          </div>
                          <div className="input-input">
                            <input formNoValidate
                              className={`input ${formDataError.name ? "inputerror" : ""}`}
                              type="text" name="name" onChange={(e) => handleInputChange(e)}
                              value={formData.name}
                            />
                            {formDataError.name && <div className='error'>
                              {t('contact.errors.' + formDataError.name)}
                            </div>}
                          </div>
                        </div>

                        <div className="input-container input-lastname">
                          <div ref={Refs.lastname} className="scrollto"></div>
                          <div className="input-label">
                            {t('contact.lastname')}
                          </div>
                          <div className="input-input">
                            <input formNoValidate
                              className={`input ${formDataError.lastname ? "inputerror" : ""}`}
                              type="text" name="lastname" onChange={(e) => handleInputChange(e)}
                              value={formData.lastname}
                            />
                            {formDataError.lastname && <div className='error'>
                              {t('contact.errors.' + formDataError.lastname)}
                            </div>}
                          </div>
                        </div>

                      </div>
                      
                      <div className="register-row register-row-ind">

                        <div className="input-container input-email">
                          <div ref={Refs.email} className="scrollto"></div>
                          <div className="input-label">
                            {t('contact.email')}
                          </div>
                          <div className="input-input">
                            <input formNoValidate
                              className={`input ${formDataError.email ? "inputerror" : ""}`}
                              type="email" name="email" onChange={(e) => handleInputChange(e)}
                              value={formData.email}
                            />
                            {formDataError.email && <div className='error'>
                              {t('contact.errors.' + formDataError.email)}
                            </div>}
                          </div>
                        </div>

                        <div className="input-container input-country">
                          <div className="input-label">
                            {t('contact.country')}
                          </div>
                          <div className="input-input">
                            <CountrySelector setValue={setCountry}
                              customStyles={customStyles} value={formData.country}></CountrySelector>
                            {formDataError.country && <div className='error'>
                              {t('contact.errors.' + formDataError.country)}
                            </div>}
                          </div>
                        </div>

                      </div>

                      <div className="register-row register-row-ind">
                      
                        <div className="input-container input-comment">
                          <div className="input-label">
                            {t('contact.comment')}
                          </div>
                          <div className="input-input">
                            <input formNoValidate
                              className={`input ${formDataError.comment ? "inputerror" : ""}`}
                              type="text" name="comment" onChange={(e) => handleInputChange(e)}
                              value={formData.comment}
                            />
                            {formDataError.comment && <div className='error'>
                              {t('contact.errors.' + formDataError.comment)}
                            </div>}
                          </div>
                        </div>
                      </div>
                      <div className="form-separator"></div>

                      <div className="register-row register-row-ind">
                        <div>
                          <div className="input-container toc-container">
                            <div ref={Refs.toc} className="scrollto"></div>

                            <input type="checkbox" className="toc"
                              onChange={(e) => handleInputChange(e)}
                              name="toc"
                              checked={formData.toc}></input>
                            <label className="label label-toc">
                              {t('contact.agreetoc')}
                              <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
                                <span className="toc-link">
                                  {t('contact.toc_link')}
                                </span>
                              </Link>
                            </label>
                          </div>
                          {formDataError.toc && <div className='error'>
                            {t('contact.errors.' + formDataError.toc)}
                          </div>}
                        </div>
                        <div className="button-container input-container">
                          <div className="button-inner">
                            <button className="button" disabled={loading}>
                              {loading ? t('contact.loading') : t('contact.register')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </GoogleReCaptchaProvider>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default RegisterFormIndividual;