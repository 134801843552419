import React, { useState, useEffect } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import Header from '../components/individual/Header';
import Overview from '../components/individual/Overview';
import Testimony from '../components/individual/Testimony';
import Program from '../components/individual/Program';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';

const IndividualProgram = (props) => {
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState([]);
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_INDIVIDUALPROGRAM);
      const { data, success } = apiResult;
      if (success) {
        let filteredText = [];
        data.forEach((datatext) => {
          filteredText[datatext.title.rendered] = datatext;
        })
        setTexts(filteredText);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <Header texts={texts['Individualheader']}></Header>
      <Overview texts={texts['Individualoverview']}></Overview>
      <Testimony texts={texts['Individualtestimony']}></Testimony>
      <Program texts={texts['Individualprogram']}></Program>
    </motion.div>
  );
};

export default IndividualProgram;