import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: process.env.REACT_APP_DEFAULT_CACHE_TIME * 60 * 1000,
  exclude: {
    query: true,
  }
})

const headers = {
  Accept: "application/json"
};

const apiClient = axios.create(
  {
    baseURL: process.env.REACT_APP_API_URL,
    //adapter: cache.adapter,
    withCredentials: true,
    timeout: 60000,
    headers: headers
  }
);

export default apiClient;