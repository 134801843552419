import React from 'react';
import { getText, formatText } from '../../utils/htmhelper';
import Reminder from '../../images/individual/reminder.png';
import RightArrow from '../../images/individual/right_arrow.png';
import { toggleIndividualRegistrationForm } from '../../redux/actions/appDataActions';
import { useDispatch } from 'react-redux';


const Overview = (props) => {
  const { texts } = props;
  const dispatch = useDispatch();
  const listItems = texts && texts.acf.list
    ? formatText(texts.acf.list).split('<br>') : [];

  return (
    <>
      <div className="ind-overview-container">
        <div className="ind-overview-inner">
          <div className="title" dangerouslySetInnerHTML={texts && getText(texts.acf.title)}></div>
          <div className="intro" dangerouslySetInnerHTML={texts && getText(texts.acf.intro)}></div>
          <div className="list-title">{texts && texts.acf.list_title}</div>
          <div className="list-container">
            {listItems.map((item, i) => (
              <div className="benefits-list-item" key={i}>
                <img src={RightArrow} alt=""></img><span dangerouslySetInnerHTML={getText(item)} />
              </div>
            ))}
          </div>
          <div className="remind-me-container">
            <span className="link" onClick={() => { dispatch(toggleIndividualRegistrationForm()) }}>
              <div className="remind-me">
                <div className="img-container"><img src={Reminder} alt="reminder"></img></div>
                <div className="text-container">{texts && texts.acf.reminderme}</div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="ind-overview-footer">
        <div className="ind-overview-footer-inner">
          {texts && texts.acf.footer}
        </div>
      </div>
    </>
  );
};

export default Overview;