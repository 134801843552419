import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";


function LanguageSwitcher(props) {
  //const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.lang);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    //dispatch(setUserLanguage(lng));
  }
  const allLanguages = [
    { code: 'en', text: 'En', flag: 'US' },
    { code: 'sp', text: 'Sp', flag: 'ES' },
    { code: 'de', text: 'De', flag: 'DE' },
    { code: 'hu', text: 'Hu', flag: 'HU' }];

  useEffect(() => {
    setLanguage(i18n.language);
  }, [setLanguage, i18n.language]);

  return (
    <>
      {allLanguages.filter(l => l.code !== language).map((l) => (
        <ReactCountryFlag key={l.code} countryCode={l.flag} onClick={() => changeLanguage(l.code)} />
      ))}
    </>
  )
}

export default LanguageSwitcher;