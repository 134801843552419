import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';
import { getPostById, getPostsFromCategory } from '../clientwrapper';
import RegisterFormGroup from '../components/register/RegisterFormGroup';

const RegisterPageGroup = (props) => {
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const [events, setEvents] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [nextEvent, setNextEvent] = useState(null);
  const {type} = props;
  const isDefault = (type === 'default');
  const eventPostId = isDefault ? null : props.match.params.eventPostId;

  const event = props.location.event ? 
    {eventId: props.location.event.nextEvent.acf.list_id, eventLabel: props.location.event.nextEvent.acf.begin} 
    : null;
  const animParams = getAnimationParams('page');

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_GROUP_REGISTRATION);
      const { data } = apiResult;
      const filteredText = data[0];
      setTexts(filteredText);
    };

    const fetchEvents = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_GROUPREGISTRATION);
      const { data } = apiResult;
      setEvents(data);
      // get the next event
      let nextEvent = { acf: { begin: "3000" } };
      data.forEach((event) => {
        let beginDate = event.acf.begin;
        if (beginDate < nextEvent.acf.begin) {
          nextEvent = event;
        }
      });
      setNextEvent(nextEvent);
    };

    const fetchEventData = async () => {
      if( eventPostId ) {
        const apiResult = await getPostById(eventPostId);
        const { data } = apiResult;
        setEventData(data[0]);
      }
    };

    if (mounted) {
      fetchData();
      fetchEvents();
      if( eventPostId ) {
        fetchEventData();
      }
    }
    return () => { setMounted(false) }
  }, [mounted, eventPostId]);

  return (
    <>
    { (isDefault || eventData) &&
    <motion.div
      transition={animParams['transition']}
      initial={animParams['initial']}
      animate={animParams['animate']}
      exit={animParams['animExit']}
      className="main-content"
    >
      <>
      <RegisterFormGroup texts={texts} event={event} events={events} nextEvent={nextEvent} eventPostId = {eventPostId} eventData = {eventData} type={type}></RegisterFormGroup>
      </>
    </motion.div>
   }
   </>
  );
};

export default RegisterPageGroup;