import React, { useEffect, useState } from 'react';
import { getPostsFromCategory } from '../clientwrapper';
import { motion } from 'framer-motion';
import { getAnimationParams } from '../utils/animationhelper';
import { formatText, getText } from '../utils/htmhelper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import titleBg from '../images/conclusions/title_bg.png';
import listDecorator from '../images/conclusions/list_decorator.png';
import wnArrow from '../images/conclusions/whatsnext-arrow.png';
import AudioPlayer from '../components/general/AudioPlayer';

const ConclusionsPage = (props) => {

  const { t } = useTranslation();
  const [mounted, setMounted] = useState(true);
  const [texts, setTexts] = useState(null);
  const animParams = getAnimationParams('page');

  const audioFile = texts ? [texts.acf.audio] : [];
  const listItems = texts && texts.acf.questions
    ? formatText(texts.acf.questions).split('<br>') : [];

  useEffect(() => {
    const fetchData = async () => {
      const apiResult = await getPostsFromCategory(process.env.REACT_APP_CATEGORY_CONCLUSIONS);
      const { data, success } = apiResult;
      if (success) {
        const filteredText = data[0];
        setTexts(filteredText);
      }
    };
    if (mounted) {
      fetchData();
    }
    return () => { setMounted(false) }
  }, [mounted]);

  return (
    <>
      <motion.div
        transition={animParams['transition']}
        initial={animParams['initial']}
        animate={animParams['animate']}
        exit={animParams['animExit']}
        className="main-content"
      >
        <div className="conclusions-container">
          <div className="conclusions-header">
            <div className="title-container" style={{ backgroundImage: `url(${titleBg})` }}>
              <div className="title">
                {texts && texts.acf.title}
              </div>
            </div>
            <div className="subtitle">
              {texts && texts.acf.sub_title}
            </div>
            <div className="audio-player-container">
              <div className="audio-player">
                {texts && texts.acf.audio && <AudioPlayer
                  isMultiple={false}
                  audiofiles={audioFile}
                  playertitle={'AUDIO OF CONCLUSIONS'}
                  maincolor={'#8E9168'}
                ></AudioPlayer>}
              </div>
            </div>
            <div className="week-review" dangerouslySetInnerHTML={texts && getText(texts.acf.week_review)}></div>
          </div>
          <div className="conclusions-questions-container">
            <div className="question-list">
              {listItems.map((item, i) => (
                <div className="question-list-item" key={i}>
                  <div className="counter">{i + 1}</div>
                  <div className="quiestion-item-container">
                    <div className="question-item">{item}</div>
                    <div className="list-decorator"><img src={listDecorator} alt=""></img></div>
                  </div>
                </div>
              ))}
            </div>

          </div>
          <div className="conclusions-footer-container">
            <div className="conclusions-footer" dangerouslySetInnerHTML={texts && getText(texts.acf.footer)}>
            </div>
          </div>
          <div className="conclusions-whatsnext-link-container">
            <Link to={{
              pathname: `/whatsnext`,
              backLink: 'conclusions',
              backText: t('whatsnext.backconclusion')
            }}>
              <div className="whatsnext-link-container">
                <div className="whatsnext-link-text">
                  <div className="link-text">{t('individual.whatsnext')}</div>
                  <div className="link-img"><img src={wnArrow} alt=""></img></div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default ConclusionsPage;